.gchqnet-thanks {
    &__image_container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__image {
        width: 100%;
    }

    &__image_caption {
        text-align: center;
        margin-top: 1em;
    }
}