.gchqnet-leaderboard-search {
    &__container {
        margin-bottom: 1em;
    }

    &__search-button {
        @include govuk-media-query(mobile, tablet) {
            margin-top: 0.5em;
        }
    }
}