@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
:root {
  --govuk-frontend-version: "5.3.1";
  --govuk-frontend-breakpoint-mobile: 20rem;
  --govuk-frontend-breakpoint-tablet: 40.0625rem;
  --govuk-frontend-breakpoint-desktop: 48.0625rem;
}

.govuk-link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-underline-offset: .1578em;
  font-family: Inter, Arial, sans-serif;
  text-decoration: underline max(1px, .0625rem);
}

@media print {
  .govuk-link {
    font-family: sans-serif;
  }
}

.govuk-link:hover {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration-skip: none;
  text-decoration-thickness: max(3px, .1875rem, .12em);
}

.govuk-link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-link:link {
  color: #1d70b8;
}

.govuk-link:visited {
  color: #4c2c92;
}

.govuk-link:hover {
  color: #003078;
}

.govuk-link:active, .govuk-link:focus {
  color: #0b0c0c;
}

@media print {
  [href^="/"].govuk-link:after, [href^="http://"].govuk-link:after, [href^="https://"].govuk-link:after {
    content: " (" attr(href) ")";
    word-wrap: break-word;
    font-size: 90%;
  }
}

.govuk-link--muted:link, .govuk-link--muted:visited {
  color: #505a5f;
}

.govuk-link--muted:hover, .govuk-link--muted:active, .govuk-link--muted:focus, .govuk-link--text-colour:link, .govuk-link--text-colour:visited {
  color: #0b0c0c;
}

@media print {
  .govuk-link--text-colour:link, .govuk-link--text-colour:visited {
    color: #000;
  }
}

.govuk-link--text-colour:hover {
  color: #0b0c0cfc;
}

.govuk-link--text-colour:active, .govuk-link--text-colour:focus {
  color: #0b0c0c;
}

@media print {
  .govuk-link--text-colour:active, .govuk-link--text-colour:focus {
    color: #000;
  }
}

.govuk-link--inverse:link, .govuk-link--inverse:visited {
  color: #fff;
}

.govuk-link--inverse:hover, .govuk-link--inverse:active {
  color: #fffffffc;
}

.govuk-link--inverse:focus {
  color: #0b0c0c;
}

.govuk-link--no-underline:not(:hover):not(:active) {
  text-decoration: none;
}

.govuk-link--no-visited-state:link, .govuk-link--no-visited-state:visited {
  color: #1d70b8;
}

.govuk-link--no-visited-state:hover {
  color: #003078;
}

.govuk-link--no-visited-state:active, .govuk-link--no-visited-state:focus {
  color: #0b0c0c;
}

.govuk-link-image {
  line-height: 0;
  text-decoration: none;
  display: inline-block;
}

.govuk-link-image:focus {
  outline: 3px solid #0000;
  box-shadow: 0 0 0 4px #fd0, 0 0 0 8px #0b0c0c;
}

.govuk-list {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-top: 0;
  margin-bottom: 15px;
  padding-left: 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  list-style-type: none;
}

@media print {
  .govuk-list {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-list {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-list {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-list {
    margin-bottom: 20px;
  }
}

.govuk-list .govuk-list {
  margin-top: 10px;
}

.govuk-list > li {
  margin-bottom: 5px;
}

.govuk-list--bullet {
  padding-left: 20px;
  list-style-type: disc;
}

.govuk-list--number {
  padding-left: 20px;
  list-style-type: decimal;
}

.govuk-list--bullet > li, .govuk-list--number > li {
  margin-bottom: 0;
}

@media (width >= 40.0625em) {
  .govuk-list--bullet > li, .govuk-list--number > li {
    margin-bottom: 5px;
  }
}

.govuk-list--spaced > li {
  margin-bottom: 10px;
}

@media (width >= 40.0625em) {
  .govuk-list--spaced > li {
    margin-bottom: 15px;
  }
}

.govuk-heading-xl {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 30px;
  font-family: Inter, Arial, sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.09375;
  display: block;
}

@media print {
  .govuk-heading-xl {
    color: #000;
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-heading-xl {
    font-size: 3rem;
    line-height: 1.04167;
  }
}

@media print {
  .govuk-heading-xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-heading-xl {
    margin-bottom: 50px;
  }
}

.govuk-heading-l {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.04167;
  display: block;
}

@media print {
  .govuk-heading-l {
    color: #000;
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-heading-l {
    font-size: 2.25rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-heading-l {
    font-size: 24pt;
    line-height: 1.05;
  }
}

@media (width >= 40.0625em) {
  .govuk-heading-l {
    margin-bottom: 30px;
  }
}

.govuk-heading-m {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.11111;
  display: block;
}

@media print {
  .govuk-heading-m {
    color: #000;
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-heading-m {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-heading-m {
    font-size: 18pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-heading-m {
    margin-bottom: 20px;
  }
}

.govuk-heading-s {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-heading-s {
    color: #000;
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-heading-s {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-heading-s {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-heading-s {
    margin-bottom: 20px;
  }
}

.govuk-caption-xl {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  margin-bottom: 5px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.11111;
  display: block;
}

@media print {
  .govuk-caption-xl {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-caption-xl {
    font-size: 1.6875rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-caption-xl {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-caption-l {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  margin-bottom: 5px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.11111;
  display: block;
}

@media print {
  .govuk-caption-l {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-caption-l {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-caption-l {
    font-size: 18pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-caption-l {
    margin-bottom: 0;
  }
}

.govuk-caption-m {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-caption-m {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-caption-m {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-caption-m {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-body-lead, .govuk-body-l {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.11111;
}

@media print {
  .govuk-body-lead, .govuk-body-l {
    color: #000;
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-body-lead, .govuk-body-l {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-body-lead, .govuk-body-l {
    font-size: 18pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-body-lead, .govuk-body-l {
    margin-bottom: 30px;
  }
}

.govuk-body, .govuk-body-m {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-body, .govuk-body-m {
    color: #000;
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-body, .govuk-body-m {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-body, .govuk-body-m {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-body, .govuk-body-m {
    margin-bottom: 20px;
  }
}

.govuk-body-s {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

@media print {
  .govuk-body-s {
    color: #000;
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-body-s {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-body-s {
    font-size: 14pt;
    line-height: 1.2;
  }
}

@media (width >= 40.0625em) {
  .govuk-body-s {
    margin-bottom: 20px;
  }
}

.govuk-body-xs {
  color: #0b0c0c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-body-xs {
    color: #000;
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-body-xs {
    font-size: .875rem;
    line-height: 1.42857;
  }
}

@media print {
  .govuk-body-xs {
    font-size: 12pt;
    line-height: 1.2;
  }
}

@media (width >= 40.0625em) {
  .govuk-body-xs {
    margin-bottom: 20px;
  }
}

.govuk-body-l + .govuk-heading-l, .govuk-body-lead + .govuk-heading-l {
  padding-top: 5px;
}

@media (width >= 40.0625em) {
  .govuk-body-l + .govuk-heading-l, .govuk-body-lead + .govuk-heading-l {
    padding-top: 10px;
  }
}

.govuk-body-m + .govuk-heading-l, .govuk-body + .govuk-heading-l, .govuk-body-s + .govuk-heading-l, .govuk-list + .govuk-heading-l {
  padding-top: 15px;
}

@media (width >= 40.0625em) {
  .govuk-body-m + .govuk-heading-l, .govuk-body + .govuk-heading-l, .govuk-body-s + .govuk-heading-l, .govuk-list + .govuk-heading-l {
    padding-top: 20px;
  }
}

.govuk-body-m + .govuk-heading-m, .govuk-body + .govuk-heading-m, .govuk-body-s + .govuk-heading-m, .govuk-list + .govuk-heading-m, .govuk-body-m + .govuk-heading-s, .govuk-body + .govuk-heading-s, .govuk-body-s + .govuk-heading-s, .govuk-list + .govuk-heading-s {
  padding-top: 5px;
}

@media (width >= 40.0625em) {
  .govuk-body-m + .govuk-heading-m, .govuk-body + .govuk-heading-m, .govuk-body-s + .govuk-heading-m, .govuk-list + .govuk-heading-m, .govuk-body-m + .govuk-heading-s, .govuk-body + .govuk-heading-s, .govuk-body-s + .govuk-heading-s, .govuk-list + .govuk-heading-s {
    padding-top: 10px;
  }
}

.govuk-section-break {
  border: 0;
  margin: 0;
}

.govuk-section-break--xl {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (width >= 40.0625em) {
  .govuk-section-break--xl {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.govuk-section-break--l {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (width >= 40.0625em) {
  .govuk-section-break--l {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.govuk-section-break--m {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (width >= 40.0625em) {
  .govuk-section-break--m {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.govuk-section-break--visible {
  border-bottom: 1px solid #b1b4b6;
}

.govuk-button-group {
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

@media (width >= 40.0625em) {
  .govuk-button-group {
    margin-bottom: 15px;
  }
}

.govuk-button-group .govuk-link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875;
  display: inline-block;
}

@media print {
  .govuk-button-group .govuk-link {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-button-group .govuk-link {
    font-size: 1.1875rem;
    line-height: 1;
  }
}

@media print {
  .govuk-button-group .govuk-link {
    font-size: 14pt;
    line-height: 19px;
  }
}

.govuk-button-group .govuk-button {
  margin-bottom: 17px;
}

@media (width >= 40.0625em) {
  .govuk-button-group {
    flex-flow: wrap;
    align-items: baseline;
    margin-right: -15px;
  }

  .govuk-button-group .govuk-button, .govuk-button-group .govuk-link {
    margin-right: 15px;
  }

  .govuk-button-group .govuk-link {
    text-align: left;
  }
}

.govuk-form-group {
  margin-bottom: 20px;
}

.govuk-form-group:after {
  content: "";
  clear: both;
  display: block;
}

@media (width >= 40.0625em) {
  .govuk-form-group {
    margin-bottom: 30px;
  }
}

.govuk-form-group .govuk-form-group:last-of-type {
  margin-bottom: 0;
}

.govuk-form-group--error {
  border-left: 5px solid #d4351c;
  padding-left: 15px;
}

.govuk-form-group--error .govuk-form-group {
  border: 0;
  padding: 0;
}

.govuk-grid-row {
  margin-left: -15px;
  margin-right: -15px;
}

.govuk-grid-row:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-grid-column-one-quarter {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (width >= 40.0625em) {
  .govuk-grid-column-one-quarter {
    float: left;
    width: 25%;
  }
}

.govuk-grid-column-one-third {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (width >= 40.0625em) {
  .govuk-grid-column-one-third {
    float: left;
    width: 33.3333%;
  }
}

.govuk-grid-column-one-half {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (width >= 40.0625em) {
  .govuk-grid-column-one-half {
    float: left;
    width: 50%;
  }
}

.govuk-grid-column-two-thirds {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (width >= 40.0625em) {
  .govuk-grid-column-two-thirds {
    float: left;
    width: 66.6667%;
  }
}

.govuk-grid-column-three-quarters {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (width >= 40.0625em) {
  .govuk-grid-column-three-quarters {
    float: left;
    width: 75%;
  }
}

.govuk-grid-column-full {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
}

@media (width >= 40.0625em) {
  .govuk-grid-column-full {
    float: left;
    width: 100%;
  }
}

.govuk-grid-column-one-quarter-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (width >= 48.0625em) {
  .govuk-grid-column-one-quarter-from-desktop {
    float: left;
    width: 25%;
  }
}

.govuk-grid-column-one-third-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (width >= 48.0625em) {
  .govuk-grid-column-one-third-from-desktop {
    float: left;
    width: 33.3333%;
  }
}

.govuk-grid-column-one-half-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (width >= 48.0625em) {
  .govuk-grid-column-one-half-from-desktop {
    float: left;
    width: 50%;
  }
}

.govuk-grid-column-two-thirds-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (width >= 48.0625em) {
  .govuk-grid-column-two-thirds-from-desktop {
    float: left;
    width: 66.6667%;
  }
}

.govuk-grid-column-three-quarters-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (width >= 48.0625em) {
  .govuk-grid-column-three-quarters-from-desktop {
    float: left;
    width: 75%;
  }
}

.govuk-grid-column-full-from-desktop {
  box-sizing: border-box;
  padding: 0 15px;
}

@media (width >= 48.0625em) {
  .govuk-grid-column-full-from-desktop {
    float: left;
    width: 100%;
  }
}

.govuk-main-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
}

@media (width >= 40.0625em) {
  .govuk-main-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.govuk-main-wrapper--auto-spacing:first-child, .govuk-main-wrapper--l {
  padding-top: 30px;
}

@media (width >= 40.0625em) {
  .govuk-main-wrapper--auto-spacing:first-child, .govuk-main-wrapper--l {
    padding-top: 50px;
  }
}

.govuk-template {
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #f3f2f1;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .govuk-template {
    scroll-padding-top: 60px;
  }

  .govuk-template:not(:has(.govuk-exit-this-page)) {
    scroll-padding-top: 0;
  }
}

@media screen {
  .govuk-template {
    overflow-y: scroll;
  }
}

.govuk-template__body {
  background-color: #fff;
  margin: 0;
}

.govuk-width-container {
  max-width: 960px;
  margin-left: 15px;
  margin-right: 15px;
}

@supports (margin: max(calc(0px))) {
  .govuk-width-container {
    margin-right: max(15px, calc(15px + env(safe-area-inset-right)));
    margin-left: max(15px, calc(15px + env(safe-area-inset-left)));
  }
}

@media (width >= 40.0625em) {
  .govuk-width-container {
    margin-left: 30px;
    margin-right: 30px;
  }

  @supports (margin: max(calc(0px))) {
    .govuk-width-container {
      margin-right: max(30px, calc(15px + env(safe-area-inset-right)));
      margin-left: max(30px, calc(15px + env(safe-area-inset-left)));
    }
  }
}

@media (width >= 1020px) {
  .govuk-width-container {
    margin-left: auto;
    margin-right: auto;
  }

  @supports (margin: max(calc(0px))) {
    .govuk-width-container {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.govuk-accordion {
  margin-bottom: 20px;
}

@media (width >= 40.0625em) {
  .govuk-accordion {
    margin-bottom: 30px;
  }
}

.govuk-accordion__section {
  padding-top: 15px;
}

.govuk-accordion__section-heading {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}

.govuk-accordion__section-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-bottom: 0;
  padding-top: 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.11111;
  display: block;
}

@media print {
  .govuk-accordion__section-button {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-accordion__section-button {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-accordion__section-button {
    color: #000;
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-accordion__section-content > :last-child {
  margin-bottom: 0;
}

.govuk-frontend-supported .govuk-accordion {
  border-bottom: 1px solid #b1b4b6;
}

.govuk-frontend-supported .govuk-accordion__section {
  padding-top: 0;
}

.govuk-frontend-supported .govuk-accordion__section-content {
  padding-top: 15px;
  padding-bottom: 30px;
  display: none;
}

@media (width >= 40.0625em) {
  .govuk-frontend-supported .govuk-accordion__section-content {
    padding-bottom: 50px;
  }
}

.govuk-frontend-supported .govuk-accordion__section-content[hidden] {
  padding-top: 0;
  padding-bottom: 0;
}

@supports (content-visibility: hidden) {
  .govuk-frontend-supported .govuk-accordion__section-content[hidden] {
    content-visibility: hidden;
    display: inherit;
  }
}

.govuk-frontend-supported .govuk-accordion__section--expanded .govuk-accordion__section-content {
  display: block;
}

.govuk-frontend-supported .govuk-accordion__show-all {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
  color: #1d70b8;
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
  border-width: 0;
  margin-bottom: 9px;
  padding: 5px 2px 5px 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  position: relative;
}

@media print {
  .govuk-frontend-supported .govuk-accordion__show-all {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-frontend-supported .govuk-accordion__show-all {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-frontend-supported .govuk-accordion__show-all {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-frontend-supported .govuk-accordion__show-all {
    margin-bottom: 14px;
  }
}

.govuk-frontend-supported .govuk-accordion__show-all::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.govuk-frontend-supported .govuk-accordion__show-all:hover {
  color: #0b0c0c;
  background: #f3f2f1;
  box-shadow: 0 -2px #f3f2f1, 0 4px #f3f2f1;
}

.govuk-frontend-supported .govuk-accordion__show-all:hover .govuk-accordion__section-toggle-text {
  color: #0b0c0c;
}

.govuk-frontend-supported .govuk-accordion__show-all:hover .govuk-accordion-nav__chevron {
  color: #0b0c0c;
  background: #0b0c0c;
}

.govuk-frontend-supported .govuk-accordion__show-all:hover .govuk-accordion-nav__chevron:after {
  color: #f3f2f1;
}

.govuk-frontend-supported .govuk-accordion__show-all:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-frontend-supported .govuk-accordion__show-all:focus .govuk-accordion-nav__chevron {
  background: #0b0c0c;
}

.govuk-frontend-supported .govuk-accordion__show-all:focus .govuk-accordion-nav__chevron:after {
  color: #fd0;
}

.govuk-frontend-supported .govuk-accordion__section-heading {
  padding: 0;
}

.govuk-frontend-supported .govuk-accordion-nav__chevron {
  box-sizing: border-box;
  vertical-align: middle;
  border: .0625rem solid;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  position: relative;
}

.govuk-frontend-supported .govuk-accordion-nav__chevron:after {
  content: "";
  box-sizing: border-box;
  border-top: .125rem solid;
  border-right: .125rem solid;
  width: .375rem;
  height: .375rem;
  display: block;
  position: absolute;
  bottom: .3125rem;
  left: .375rem;
  transform: rotate(-45deg);
}

.govuk-frontend-supported .govuk-accordion-nav__chevron--down {
  transform: rotate(180deg);
}

.govuk-frontend-supported .govuk-accordion__section-button {
  color: #0b0c0c;
  text-align: left;
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
  border: 0;
  border-top: 1px solid #b1b4b6;
  border-bottom: 10px solid #0000;
  width: 100%;
  padding: 10px 0 0;
}

@media (width >= 40.0625em) {
  .govuk-frontend-supported .govuk-accordion__section-button {
    padding-bottom: 10px;
  }
}

.govuk-frontend-supported .govuk-accordion__section-button:active {
  color: #0b0c0c;
  background: none;
}

.govuk-frontend-supported .govuk-accordion__section-button:hover {
  color: #0b0c0c;
  background: #f3f2f1;
}

.govuk-frontend-supported .govuk-accordion__section-button:hover .govuk-accordion__section-toggle-text {
  color: #0b0c0c;
}

.govuk-frontend-supported .govuk-accordion__section-button:hover .govuk-accordion-nav__chevron {
  color: #0b0c0c;
  background: #0b0c0c;
}

.govuk-frontend-supported .govuk-accordion__section-button:hover .govuk-accordion-nav__chevron:after {
  color: #f3f2f1;
}

.govuk-frontend-supported .govuk-accordion__section-button:focus {
  outline: 0;
}

.govuk-frontend-supported .govuk-accordion__section-button:focus .govuk-accordion__section-heading-text-focus, .govuk-frontend-supported .govuk-accordion__section-button:focus .govuk-accordion__section-summary-focus, .govuk-frontend-supported .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-frontend-supported .govuk-accordion__section-button:focus .govuk-accordion-nav__chevron {
  color: #0b0c0c;
  background: #0b0c0c;
}

.govuk-frontend-supported .govuk-accordion__section-button:focus .govuk-accordion-nav__chevron:after {
  color: #fd0;
}

.govuk-frontend-supported .govuk-accordion__section-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.govuk-frontend-supported .govuk-accordion__section--expanded .govuk-accordion__section-button {
  border-bottom: 0;
  padding-bottom: 15px;
}

@media (width >= 40.0625em) {
  .govuk-frontend-supported .govuk-accordion__section--expanded .govuk-accordion__section-button {
    padding-bottom: 20px;
  }
}

.govuk-frontend-supported .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus {
  padding-bottom: 3px;
}

@media (width >= 48.0625em) {
  .govuk-frontend-supported .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus {
    padding-bottom: 2px;
  }
}

.govuk-frontend-supported .govuk-accordion__section-toggle, .govuk-frontend-supported .govuk-accordion__section-heading-text, .govuk-frontend-supported .govuk-accordion__section-summary {
  margin-bottom: 13px;
  display: block;
}

.govuk-frontend-supported .govuk-accordion__section-toggle .govuk-accordion__section-heading-text-focus, .govuk-frontend-supported .govuk-accordion__section-toggle .govuk-accordion__section-summary-focus, .govuk-frontend-supported .govuk-accordion__section-toggle .govuk-accordion__section-toggle-focus, .govuk-frontend-supported .govuk-accordion__section-heading-text .govuk-accordion__section-heading-text-focus, .govuk-frontend-supported .govuk-accordion__section-heading-text .govuk-accordion__section-summary-focus, .govuk-frontend-supported .govuk-accordion__section-heading-text .govuk-accordion__section-toggle-focus, .govuk-frontend-supported .govuk-accordion__section-summary .govuk-accordion__section-heading-text-focus, .govuk-frontend-supported .govuk-accordion__section-summary .govuk-accordion__section-summary-focus, .govuk-frontend-supported .govuk-accordion__section-summary .govuk-accordion__section-toggle-focus {
  display: inline;
}

.govuk-frontend-supported .govuk-accordion__section-toggle {
  color: #1d70b8;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media (width >= 40.0625em) {
  .govuk-frontend-supported .govuk-accordion__section-toggle {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-frontend-supported .govuk-accordion__section-toggle {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-frontend-supported .govuk-accordion__show-all-text, .govuk-frontend-supported .govuk-accordion__section-toggle-text {
  vertical-align: middle;
  margin-left: 5px;
}

@media screen and (forced-colors: active) {
  .govuk-frontend-supported .govuk-accordion__show-all:hover .govuk-accordion-nav__chevron, .govuk-frontend-supported .govuk-accordion__section-button:hover .govuk-accordion-nav__chevron {
    background-color: #0000;
  }

  .govuk-frontend-supported .govuk-accordion__show-all:focus .govuk-accordion__section-heading-text-focus, .govuk-frontend-supported .govuk-accordion__show-all:focus .govuk-accordion__section-summary-focus, .govuk-frontend-supported .govuk-accordion__show-all:focus .govuk-accordion__section-toggle-focus, .govuk-frontend-supported .govuk-accordion__show-all:focus .govuk-accordion-nav__chevron, .govuk-frontend-supported .govuk-accordion__section-button:focus .govuk-accordion__section-heading-text-focus, .govuk-frontend-supported .govuk-accordion__section-button:focus .govuk-accordion__section-summary-focus, .govuk-frontend-supported .govuk-accordion__section-button:focus .govuk-accordion__section-toggle-focus, .govuk-frontend-supported .govuk-accordion__section-button:focus .govuk-accordion-nav__chevron {
    background: none;
  }
}

@media (hover: none) {
  .govuk-frontend-supported .govuk-accordion__section-header:hover {
    border-top-color: #b1b4b6;
    box-shadow: inset 0 3px #1d70b8;
  }

  .govuk-frontend-supported .govuk-accordion__section-header:hover .govuk-accordion__section-button {
    border-top-color: #b1b4b6;
  }
}

.govuk-back-link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-underline-offset: .1578em;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: .875em;
  font-family: Inter, Arial, sans-serif;
  font-size: .875rem;
  line-height: 1.14286;
  text-decoration: underline max(1px, .0625rem);
  display: inline-block;
  position: relative;
}

@media (width >= 40.0625em) {
  .govuk-back-link {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-back-link {
    font-family: sans-serif;
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-back-link:hover {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration-skip: none;
  text-decoration-thickness: max(3px, .1875rem, .12em);
}

.govuk-back-link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-back-link:link, .govuk-back-link:visited {
  color: #0b0c0c;
}

@media print {
  .govuk-back-link:link, .govuk-back-link:visited {
    color: #000;
  }
}

.govuk-back-link:hover {
  color: #0b0c0cfc;
}

.govuk-back-link:active, .govuk-back-link:focus {
  color: #0b0c0c;
}

@media print {
  .govuk-back-link:active, .govuk-back-link:focus {
    color: #000;
  }
}

.govuk-back-link:before {
  content: "";
  border: 1px solid #505a5f;
  border-width: 1px 1px 0 0;
  width: .4375em;
  height: .4375em;
  margin: auto 0;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: .1875em;
  transform: rotate(225deg);
}

@supports (border-width: max(0px)) {
  .govuk-back-link:before {
    border-width: max(1px, .0625em) max(1px, .0625em) 0 0;
    font-size: max(16px, 1em);
  }
}

.govuk-back-link:focus:before {
  border-color: #0b0c0c;
}

.govuk-back-link:after {
  content: "";
  position: absolute;
  inset: -14px 0;
}

.govuk-back-link--inverse:link, .govuk-back-link--inverse:visited {
  color: #fff;
}

.govuk-back-link--inverse:hover, .govuk-back-link--inverse:active {
  color: #fffffffc;
}

.govuk-back-link--inverse:focus {
  color: #0b0c0c;
}

.govuk-back-link--inverse:before {
  border-color: currentColor;
}

.govuk-breadcrumbs {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: Inter, Arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

@media print {
  .govuk-breadcrumbs {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-breadcrumbs {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-breadcrumbs {
    color: #000;
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-breadcrumbs__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.govuk-breadcrumbs__list:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-breadcrumbs__list-item {
  float: left;
  margin-bottom: 5px;
  margin-left: .625em;
  padding-left: .978438em;
  display: inline-block;
  position: relative;
}

.govuk-breadcrumbs__list-item:before {
  content: "";
  border: 1px solid #505a5f;
  border-width: 1px 1px 0 0;
  width: .4375em;
  height: .4375em;
  margin: auto 0;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -.206875em;
  transform: rotate(45deg);
}

@supports (border-width: max(0px)) {
  .govuk-breadcrumbs__list-item:before {
    border-width: max(1px, .0625em) max(1px, .0625em) 0 0;
    font-size: max(16px, 1em);
  }
}

.govuk-breadcrumbs__list-item:first-child {
  margin-left: 0;
  padding-left: 0;
}

.govuk-breadcrumbs__list-item:first-child:before {
  content: none;
  display: none;
}

.govuk-breadcrumbs__link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-underline-offset: .1578em;
  font-family: Inter, Arial, sans-serif;
  text-decoration: underline max(1px, .0625rem);
}

@media print {
  .govuk-breadcrumbs__link {
    font-family: sans-serif;
  }
}

.govuk-breadcrumbs__link:hover {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration-skip: none;
  text-decoration-thickness: max(3px, .1875rem, .12em);
}

.govuk-breadcrumbs__link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-breadcrumbs__link:link, .govuk-breadcrumbs__link:visited {
  color: #0b0c0c;
}

@media print {
  .govuk-breadcrumbs__link:link, .govuk-breadcrumbs__link:visited {
    color: #000;
  }
}

.govuk-breadcrumbs__link:hover {
  color: #0b0c0cfc;
}

.govuk-breadcrumbs__link:active, .govuk-breadcrumbs__link:focus {
  color: #0b0c0c;
}

@media print {
  .govuk-breadcrumbs__link:active, .govuk-breadcrumbs__link:focus {
    color: #000;
  }
}

@media (width <= 40.0525em) {
  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item {
    display: none;
  }

  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item:first-child, .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item:last-child {
    display: inline-block;
  }

  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list-item:before {
    margin: 0;
    top: .375em;
  }

  .govuk-breadcrumbs--collapse-on-mobile .govuk-breadcrumbs__list {
    display: flex;
  }
}

.govuk-breadcrumbs--inverse, .govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:link, .govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:visited {
  color: #fff;
}

.govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:hover, .govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:active {
  color: #fffffffc;
}

.govuk-breadcrumbs--inverse .govuk-breadcrumbs__link:focus {
  color: #0b0c0c;
}

.govuk-breadcrumbs--inverse .govuk-breadcrumbs__list-item:before {
  border-color: currentColor;
}

.govuk-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #00703c;
  border: 2px solid #0000;
  border-radius: 0;
  width: 100%;
  margin: 0 0 22px;
  padding: 8px 10px 7px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875;
  display: inline-block;
  position: relative;
  box-shadow: 0 2px #002d18;
}

@media print {
  .govuk-button {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-button {
    font-size: 1.1875rem;
    line-height: 1;
  }
}

@media print {
  .govuk-button {
    font-size: 14pt;
    line-height: 19px;
  }
}

@media (width >= 40.0625em) {
  .govuk-button {
    width: auto;
    margin-bottom: 32px;
  }
}

.govuk-button:link, .govuk-button:visited, .govuk-button:active, .govuk-button:hover {
  color: #fff;
  text-decoration: none;
}

.govuk-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.govuk-button:hover {
  background-color: #005a30;
}

.govuk-button:active {
  top: 2px;
}

.govuk-button:focus {
  border-color: #fd0;
  outline: 3px solid #0000;
  box-shadow: inset 0 0 0 1px #fd0;
}

.govuk-button:focus:not(:active):not(:hover) {
  color: #0b0c0c;
  background-color: #fd0;
  border-color: #fd0;
  box-shadow: 0 2px #0b0c0c;
}

.govuk-button:before {
  content: "";
  background: none;
  display: block;
  position: absolute;
  inset: -2px -2px -4px;
}

.govuk-button:active:before {
  top: -4px;
}

.govuk-button[disabled] {
  opacity: .5;
}

.govuk-button[disabled]:hover {
  cursor: not-allowed;
  background-color: #00703c;
}

.govuk-button[disabled]:active {
  top: 0;
  box-shadow: 0 2px #002d18;
}

.govuk-button--secondary {
  background-color: #f3f2f1;
  box-shadow: 0 2px #929191;
}

.govuk-button--secondary, .govuk-button--secondary:link, .govuk-button--secondary:visited, .govuk-button--secondary:active, .govuk-button--secondary:hover {
  color: #0b0c0c;
}

.govuk-button--secondary:hover {
  background-color: #dbdad9;
}

.govuk-button--secondary:hover[disabled] {
  background-color: #f3f2f1;
}

.govuk-button--warning {
  background-color: #d4351c;
  box-shadow: 0 2px #55150b;
}

.govuk-button--warning, .govuk-button--warning:link, .govuk-button--warning:visited, .govuk-button--warning:active, .govuk-button--warning:hover {
  color: #fff;
}

.govuk-button--warning:hover {
  background-color: #aa2a16;
}

.govuk-button--warning:hover[disabled] {
  background-color: #d4351c;
}

.govuk-button--inverse {
  background-color: #fff;
  box-shadow: 0 2px #144e81;
}

.govuk-button--inverse, .govuk-button--inverse:link, .govuk-button--inverse:visited, .govuk-button--inverse:active, .govuk-button--inverse:hover {
  color: #1d70b8;
}

.govuk-button--inverse:hover {
  background-color: #e8f1f8;
}

.govuk-button--inverse:hover[disabled] {
  background-color: #fff;
}

.govuk-button--start {
  justify-content: center;
  min-height: auto;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
  display: inline-flex;
}

@media (width >= 40.0625em) {
  .govuk-button--start {
    font-size: 1.5rem;
    line-height: 1;
  }
}

@media print {
  .govuk-button--start {
    font-size: 18pt;
    line-height: 1;
  }
}

.govuk-button__start-icon {
  vertical-align: middle;
  forced-color-adjust: auto;
  flex-shrink: 0;
  align-self: center;
  margin-left: 5px;
}

@media (width >= 48.0625em) {
  .govuk-button__start-icon {
    margin-left: 10px;
  }
}

.govuk-error-message {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  clear: both;
  color: #d4351c;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-error-message {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-error-message {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-error-message {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-hint {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505a5f;
  margin-bottom: 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-hint {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-hint {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-hint {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-label:not(.govuk-label--m):not(.govuk-label--l):not(.govuk-label--xl) + .govuk-hint, .govuk-fieldset__legend:not(.govuk-fieldset__legend--m):not(.govuk-fieldset__legend--l):not(.govuk-fieldset__legend--xl) + .govuk-hint {
  margin-bottom: 10px;
}

.govuk-fieldset__legend + .govuk-hint {
  margin-top: -5px;
}

.govuk-label {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-bottom: 5px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-label {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-label {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-label {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-label--xl, .govuk-label--l, .govuk-label--m {
  margin-bottom: 15px;
  font-weight: 600;
}

.govuk-label--xl {
  font-size: 2rem;
  line-height: 1.09375;
}

@media (width >= 40.0625em) {
  .govuk-label--xl {
    font-size: 3rem;
    line-height: 1.04167;
  }
}

@media print {
  .govuk-label--xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-label--l {
  font-size: 1.5rem;
  line-height: 1.04167;
}

@media (width >= 40.0625em) {
  .govuk-label--l {
    font-size: 2.25rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-label--l {
    font-size: 24pt;
    line-height: 1.05;
  }
}

.govuk-label--m {
  font-size: 1.125rem;
  line-height: 1.11111;
}

@media (width >= 40.0625em) {
  .govuk-label--m {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-label--m {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-label--s {
  font-weight: 600;
}

.govuk-label-wrapper {
  margin: 0;
}

.govuk-textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  resize: vertical;
  -webkit-appearance: none;
  border: 2px solid #0b0c0c;
  border-radius: 0;
  width: 100%;
  min-height: 40px;
  margin-bottom: 20px;
  padding: 5px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-textarea {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-textarea {
    font-size: 1.1875rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-textarea {
    font-size: 14pt;
    line-height: 1.25;
  }
}

@media (width >= 40.0625em) {
  .govuk-textarea {
    margin-bottom: 30px;
  }
}

.govuk-textarea:focus {
  outline-offset: 0;
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 2px;
}

.govuk-textarea:disabled {
  opacity: .5;
  color: inherit;
  cursor: not-allowed;
  background-color: #0000;
}

.govuk-textarea--error {
  border-color: #d4351c;
}

.govuk-textarea--error:focus {
  border-color: #0b0c0c;
}

.govuk-character-count {
  margin-bottom: 20px;
}

@media (width >= 40.0625em) {
  .govuk-character-count {
    margin-bottom: 30px;
  }
}

.govuk-character-count .govuk-form-group, .govuk-character-count .govuk-textarea {
  margin-bottom: 5px;
}

.govuk-character-count__message {
  font-variant-numeric: tabular-nums;
  margin-top: 0;
  margin-bottom: 0;
}

.govuk-character-count__message:after {
  content: "​";
}

.govuk-character-count__message--disabled {
  visibility: hidden;
}

.govuk-fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

.govuk-fieldset:after {
  content: "";
  clear: both;
  display: block;
}

@supports not (caret-color: auto) {
  .govuk-fieldset {
    display: table-cell;
  }

  x:-moz-any-link {
    display: table-cell;
  }
}

.govuk-fieldset__legend {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  box-sizing: border-box;
  white-space: normal;
  max-width: 100%;
  margin-bottom: 10px;
  padding: 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: table;
}

@media print {
  .govuk-fieldset__legend {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-fieldset__legend {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-fieldset__legend {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-fieldset__legend--xl, .govuk-fieldset__legend--l, .govuk-fieldset__legend--m {
  margin-bottom: 15px;
  font-weight: 600;
}

.govuk-fieldset__legend--xl {
  font-size: 2rem;
  line-height: 1.09375;
}

@media (width >= 40.0625em) {
  .govuk-fieldset__legend--xl {
    font-size: 3rem;
    line-height: 1.04167;
  }
}

@media print {
  .govuk-fieldset__legend--xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-fieldset__legend--l {
  font-size: 1.5rem;
  line-height: 1.04167;
}

@media (width >= 40.0625em) {
  .govuk-fieldset__legend--l {
    font-size: 2.25rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-fieldset__legend--l {
    font-size: 24pt;
    line-height: 1.05;
  }
}

.govuk-fieldset__legend--m {
  font-size: 1.125rem;
  line-height: 1.11111;
}

@media (width >= 40.0625em) {
  .govuk-fieldset__legend--m {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-fieldset__legend--m {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-fieldset__legend--s {
  font-weight: 600;
}

.govuk-fieldset__heading {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.govuk-checkboxes__item {
  flex-wrap: wrap;
  margin-bottom: 10px;
  display: flex;
  position: relative;
}

.govuk-checkboxes__item:last-child, .govuk-checkboxes__item:last-of-type {
  margin-bottom: 0;
}

.govuk-checkboxes__input {
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  width: 44px;
  height: 44px;
  margin: 0;
}

.govuk-checkboxes__label {
  cursor: pointer;
  touch-action: manipulation;
  align-self: center;
  max-width: calc(100% - 74px);
  margin-bottom: 0;
  padding: 7px 15px;
}

.govuk-checkboxes__label:before {
  content: "";
  box-sizing: border-box;
  background: none;
  border: 2px solid;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.govuk-checkboxes__label:after {
  content: "";
  box-sizing: border-box;
  opacity: 0;
  background: none;
  border-top: 0 solid #0000;
  border-bottom: 5px solid;
  border-left: 5px solid;
  border-right: 0 solid;
  width: 23px;
  height: 12px;
  position: absolute;
  top: 13px;
  left: 10px;
  transform: rotate(-45deg);
}

.govuk-checkboxes__hint {
  width: 100%;
  margin-top: -5px;
  padding-left: 59px;
  padding-right: 15px;
  display: block;
}

.govuk-label:not(.govuk-label--m):not(.govuk-label--l):not(.govuk-label--xl) + .govuk-checkboxes__hint {
  margin-bottom: 0;
}

.govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
  outline-offset: 1px;
  border-width: 4px;
  outline: 3px solid #0000;
  box-shadow: 0 0 0 3px #fd0;
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
    outline-color: highlight;
  }
}

.govuk-checkboxes__input:checked + .govuk-checkboxes__label:after {
  opacity: 1;
}

.govuk-checkboxes__input:disabled, .govuk-checkboxes__input:disabled + .govuk-checkboxes__label {
  cursor: not-allowed;
}

.govuk-checkboxes__input:disabled + .govuk-checkboxes__label, .govuk-checkboxes__input:disabled ~ .govuk-hint {
  opacity: .5;
}

.govuk-checkboxes__divider {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  text-align: center;
  width: 40px;
  margin-bottom: 10px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-checkboxes__divider {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-checkboxes__divider {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-checkboxes__divider {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-checkboxes__conditional {
  border-left: 4px solid #b1b4b6;
  margin-bottom: 15px;
  margin-left: 18px;
  padding-left: 33px;
}

@media (width >= 40.0625em) {
  .govuk-checkboxes__conditional {
    margin-bottom: 20px;
  }
}

.govuk-frontend-supported .govuk-checkboxes__conditional--hidden {
  display: none;
}

.govuk-checkboxes__conditional > :last-child, .govuk-checkboxes--small .govuk-checkboxes__item {
  margin-bottom: 0;
}

.govuk-checkboxes--small .govuk-checkboxes__input {
  margin-left: -10px;
}

.govuk-checkboxes--small .govuk-checkboxes__label {
  padding-left: 1px;
}

.govuk-checkboxes--small .govuk-checkboxes__label:before {
  width: 24px;
  height: 24px;
  top: 10px;
  left: 0;
}

.govuk-checkboxes--small .govuk-checkboxes__label:after {
  border-width: 0 0 3px 3px;
  width: 12px;
  height: 6.5px;
  top: 17px;
  left: 6px;
}

.govuk-checkboxes--small .govuk-checkboxes__hint {
  padding-left: 34px;
}

.govuk-checkboxes--small .govuk-checkboxes__conditional {
  margin-left: 10px;
  padding-left: 20px;
}

.govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before {
  outline-offset: 1px;
  outline: 3px dashed #0000;
  box-shadow: 0 0 0 10px #b1b4b6;
}

.govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
  box-shadow: 0 0 0 3px #fd0, 0 0 0 10px #b1b4b6;
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
    outline-color: highlight;
  }
}

@media (hover: none), (pointer: coarse) {
  .govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before {
    box-shadow: initial;
  }

  .govuk-checkboxes--small .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
    box-shadow: 0 0 0 3px #fd0;
  }
}

.govuk-cookie-banner {
  background-color: #f3f2f1;
  border-bottom: 10px solid #0000;
  padding-top: 20px;
}

.govuk-cookie-banner[hidden] {
  display: none;
}

.govuk-cookie-banner__message {
  margin-bottom: -10px;
}

.govuk-cookie-banner__message[hidden] {
  display: none;
}

.govuk-cookie-banner__message:focus {
  outline: none;
}

.govuk-input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  appearance: none;
  border: 2px solid #0b0c0c;
  border-radius: 0;
  width: 100%;
  height: 2.5rem;
  margin-top: 0;
  padding: 5px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-input {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-input {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-input {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-input:focus {
  outline-offset: 0;
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 2px;
}

.govuk-input:disabled {
  opacity: .5;
  color: inherit;
  cursor: not-allowed;
  background-color: #0000;
}

.govuk-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.govuk-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.govuk-input[type="number"] {
  -moz-appearance: textfield;
}

.govuk-input--error {
  border-color: #d4351c;
}

.govuk-input--error:focus {
  border-color: #0b0c0c;
}

.govuk-input--extra-letter-spacing {
  font-variant-numeric: tabular-nums;
  letter-spacing: .05em;
}

.govuk-input--width-30 {
  max-width: 29.5em;
}

.govuk-input--width-20 {
  max-width: 20.5em;
}

.govuk-input--width-10 {
  max-width: 11.5em;
}

.govuk-input--width-5 {
  max-width: 5.5em;
}

.govuk-input--width-4 {
  max-width: 4.5em;
}

.govuk-input--width-3 {
  max-width: 3.75em;
}

.govuk-input--width-2 {
  max-width: 2.75em;
}

.govuk-input__wrapper {
  display: flex;
}

.govuk-input__wrapper .govuk-input {
  flex: 0 auto;
}

.govuk-input__wrapper .govuk-input:focus {
  z-index: 1;
}

@media (width <= 19.99em) {
  .govuk-input__wrapper {
    display: block;
  }

  .govuk-input__wrapper .govuk-input {
    max-width: 100%;
  }
}

.govuk-input__prefix, .govuk-input__suffix {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  cursor: default;
  background-color: #f3f2f1;
  border: 2px solid #0b0c0c;
  flex: none;
  justify-content: center;
  align-items: center;
  min-width: 2.5rem;
  height: 2.5rem;
  padding: 5px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: flex;
}

@media print {
  .govuk-input__prefix, .govuk-input__suffix {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-input__prefix, .govuk-input__suffix {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-input__prefix, .govuk-input__suffix {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width <= 19.99em) {
  .govuk-input__prefix, .govuk-input__suffix {
    white-space: normal;
    height: 100%;
    display: block;
  }

  .govuk-input__prefix {
    border-bottom: 0;
  }
}

@media (width >= 20em) {
  .govuk-input__prefix {
    border-right: 0;
  }
}

@media (width <= 19.99em) {
  .govuk-input__suffix {
    border-top: 0;
  }
}

@media (width >= 20em) {
  .govuk-input__suffix {
    border-left: 0;
  }
}

.govuk-date-input {
  font-size: 0;
}

.govuk-date-input:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-date-input__item {
  margin-bottom: 0;
  margin-right: 20px;
  display: inline-block;
}

.govuk-date-input__label {
  display: block;
}

.govuk-date-input__input {
  margin-bottom: 0;
}

.govuk-details {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin-bottom: 20px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
}

@media print {
  .govuk-details {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-details {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-details {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-details {
    margin-bottom: 30px;
  }
}

.govuk-details__summary {
  margin-bottom: 5px;
  display: inline-block;
}

.govuk-details__summary-text > :first-child {
  margin-top: 0;
}

.govuk-details__summary-text > :only-child, .govuk-details__summary-text > :last-child {
  margin-bottom: 0;
}

.govuk-details__text {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
}

.govuk-details__text p {
  margin-top: 0;
  margin-bottom: 20px;
}

.govuk-details__text > :last-child {
  margin-bottom: 0;
}

@media screen� {
  .govuk-details {
    border-left: 10px solid #b1b4b6;
  }

  .govuk-details__summary {
    margin-top: 15px;
  }

  .govuk-details__summary-text {
    margin-bottom: 15px;
    padding-left: 20px;
    font-weight: 600;
  }
}

@media screen� and (width >= 40.0625em) {
  .govuk-details__summary-text {
    margin-bottom: 20px;
  }
}

@supports not (-ms-ime-align: auto) {
  .govuk-details__summary {
    color: #1d70b8;
    cursor: pointer;
    padding-left: 25px;
    position: relative;
  }

  .govuk-details__summary:hover {
    color: #003078;
  }

  .govuk-details__summary:focus {
    color: #0b0c0c;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    background-color: #fd0;
    outline: 3px solid #0000;
    text-decoration: none;
    box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
  }

  .govuk-details__summary-text {
    text-underline-offset: .1578em;
    text-decoration: underline max(1px, .0625rem);
  }

  .govuk-details__summary:hover .govuk-details__summary-text {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration-skip: none;
    text-decoration-thickness: max(3px, .1875rem, .12em);
  }

  .govuk-details__summary:focus .govuk-details__summary-text {
    text-decoration: none;
  }

  .govuk-details__summary::-webkit-details-marker {
    display: none;
  }

  .govuk-details__summary:before {
    content: "";
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
    border: 7px solid #0000;
    border-width: 7px 0 7px 12.124px;
    border-left-color: inherit;
    width: 0;
    height: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: -1px;
    bottom: 0;
    left: 0;
  }

  .govuk-details[open] > .govuk-details__summary:before {
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    border: 7px solid #0000;
    border-width: 12.124px 7px 0;
    border-top-color: inherit;
    width: 0;
    height: 0;
    display: block;
  }

  .govuk-details__text {
    border-left: 5px solid #b1b4b6;
  }
}

.govuk-error-summary {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  border: 5px solid #d4351c;
  margin-bottom: 30px;
  padding: 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-error-summary {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-error-summary {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-error-summary {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-error-summary {
    margin-bottom: 50px;
    padding: 20px;
  }
}

.govuk-error-summary:focus {
  outline: 3px solid #fd0;
}

.govuk-error-summary__title {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.11111;
}

@media (width >= 40.0625em) {
  .govuk-error-summary__title {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-error-summary__title {
    font-size: 18pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-error-summary__title {
    margin-bottom: 20px;
  }
}

.govuk-error-summary__body p {
  margin-top: 0;
  margin-bottom: 15px;
}

@media (width >= 40.0625em) {
  .govuk-error-summary__body p {
    margin-bottom: 20px;
  }
}

.govuk-error-summary__list {
  margin-top: 0;
  margin-bottom: 0;
}

.govuk-error-summary__list a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-underline-offset: .1578em;
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  text-decoration: underline max(1px, .0625rem);
}

@media print {
  .govuk-error-summary__list a {
    font-family: sans-serif;
  }
}

.govuk-error-summary__list a:hover {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration-skip: none;
  text-decoration-thickness: max(3px, .1875rem, .12em);
}

.govuk-error-summary__list a:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-error-summary__list a:link, .govuk-error-summary__list a:visited {
  color: #d4351c;
}

.govuk-error-summary__list a:hover {
  color: #942514;
}

.govuk-error-summary__list a:active {
  color: #d4351c;
}

.govuk-error-summary__list a:focus {
  color: #0b0c0c;
}

.govuk-exit-this-page {
  z-index: 1000;
  width: 100%;
  margin-bottom: 30px;
  position: sticky;
  top: 0;
  left: 0;
}

@media (width >= 40.0625em) {
  .govuk-exit-this-page {
    float: right;
    width: auto;
    margin-bottom: 50px;
    display: inline-block;
    left: auto;
    right: 0;
  }
}

.govuk-exit-this-page__button {
  margin-bottom: 0;
}

.govuk-exit-this-page__indicator {
  color: inherit;
  text-align: center;
  pointer-events: none;
  padding: 10px 10px 0;
  line-height: 0;
  display: none;
}

.govuk-exit-this-page__indicator--visible {
  display: block;
}

.govuk-exit-this-page__indicator-light {
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 50%;
  width: .75em;
  height: .75em;
  margin: 0 .125em;
  display: inline-block;
}

.govuk-exit-this-page__indicator-light--on {
  border-width: .375em;
}

@media only print {
  .govuk-exit-this-page {
    display: none;
  }
}

.govuk-exit-this-page-overlay {
  z-index: 9999;
  background-color: #fff;
  position: fixed;
  inset: 0;
}

.govuk-exit-this-page-hide-content * {
  display: none !important;
}

.govuk-exit-this-page-hide-content .govuk-exit-this-page-overlay {
  display: block !important;
}

.govuk-file-upload {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  max-width: 100%;
  margin-left: -5px;
  padding: 5px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-file-upload {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-file-upload {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-file-upload {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-file-upload::-webkit-file-upload-button {
  -webkit-appearance: button;
  color: inherit;
  font: inherit;
}

.govuk-file-upload:focus, .govuk-file-upload:focus-within {
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 4px #0b0c0c;
}

.govuk-file-upload:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.govuk-footer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  background: #f3f2f1;
  border-top: 1px solid #b1b4b6;
  padding-top: 25px;
  padding-bottom: 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
}

@media print {
  .govuk-footer {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-footer {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-footer {
    font-size: 14pt;
    line-height: 1.2;
  }
}

@media (width >= 40.0625em) {
  .govuk-footer {
    padding-top: 40px;
    padding-bottom: 25px;
  }
}

.govuk-footer__link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-underline-offset: .1578em;
  font-family: Inter, Arial, sans-serif;
  text-decoration: underline max(1px, .0625rem);
}

@media print {
  .govuk-footer__link {
    font-family: sans-serif;
  }
}

.govuk-footer__link:hover {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration-skip: none;
  text-decoration-thickness: max(3px, .1875rem, .12em);
}

.govuk-footer__link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-footer__link:link, .govuk-footer__link:visited {
  color: #0b0c0c;
}

@media print {
  .govuk-footer__link:link, .govuk-footer__link:visited {
    color: #000;
  }
}

.govuk-footer__link:hover {
  color: #0b0c0cfc;
}

.govuk-footer__link:active, .govuk-footer__link:focus {
  color: #0b0c0c;
}

@media print {
  .govuk-footer__link:active, .govuk-footer__link:focus {
    color: #000;
  }
}

.govuk-footer__section-break {
  border: 0;
  border-bottom: 1px solid #b1b4b6;
  margin: 0 0 30px;
}

@media (width >= 40.0625em) {
  .govuk-footer__section-break {
    margin-bottom: 50px;
  }
}

.govuk-footer__meta {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.govuk-footer__meta-item {
  margin-bottom: 25px;
  margin-left: 15px;
  margin-right: 15px;
}

.govuk-footer__meta-item--grow {
  flex: 1;
}

@media (width <= 40.0525em) {
  .govuk-footer__meta-item--grow {
    flex-basis: 320px;
  }
}

.govuk-footer__licence-logo {
  vertical-align: top;
  forced-color-adjust: auto;
  margin-right: 10px;
  display: inline-block;
}

@media (width <= 48.0525em) {
  .govuk-footer__licence-logo {
    margin-bottom: 15px;
  }
}

.govuk-footer__licence-description {
  display: inline-block;
}

.govuk-footer__copyright-logo {
  background-image: "";
  text-align: center;
  white-space: nowrap;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 125px 102px;
  min-width: 125px;
  padding-top: 112px;
  display: inline-block;
}

@media only screen and (-webkit-device-pixel-ratio >= 2), only screen and (resolution >= 192dpi), only screen and (resolution >= 2x) {
  .govuk-footer__copyright-logo {
    background-image: "";
  }
}

.govuk-footer__inline-list {
  margin-top: 0;
  margin-bottom: 15px;
  padding: 0;
}

.govuk-footer__meta-custom {
  margin-bottom: 20px;
}

.govuk-footer__inline-list-item {
  margin-bottom: 5px;
  margin-right: 15px;
  display: inline-block;
}

.govuk-footer__heading {
  border-bottom: 1px solid #b1b4b6;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

@media (width <= 40.0525em) {
  .govuk-footer__heading {
    padding-bottom: 10px;
  }
}

.govuk-footer__navigation {
  margin-left: -15px;
  margin-right: -15px;
}

.govuk-footer__navigation:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-footer__section {
  vertical-align: top;
  margin-bottom: 30px;
  display: inline-block;
}

.govuk-footer__list {
  column-gap: 30px;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (width >= 48.0625em) {
  .govuk-footer__list--columns-2 {
    column-count: 2;
  }

  .govuk-footer__list--columns-3 {
    column-count: 3;
  }
}

.govuk-footer__list-item {
  margin-bottom: 15px;
}

@media (width >= 40.0625em) {
  .govuk-footer__list-item {
    margin-bottom: 20px;
  }
}

.govuk-footer__list-item:last-child {
  margin-bottom: 0;
}

.govuk-header {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: #0b0c0c;
  border-bottom: 10px solid #fff;
  font-family: Inter, Arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1;
}

@media print {
  .govuk-header {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-header {
    font-size: 1rem;
    line-height: 1;
  }
}

@media print {
  .govuk-header {
    font-size: 14pt;
    line-height: 1;
  }
}

.govuk-header__container--full-width {
  border-color: #1d70b8;
  padding: 0 15px;
}

.govuk-header__container--full-width .govuk-header__menu-button {
  right: 15px;
}

.govuk-header__container {
  border-bottom: 10px solid #1d70b8;
  margin-bottom: -10px;
  padding-top: 10px;
  position: relative;
}

.govuk-header__container:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-header__logotype {
  fill: currentColor;
  vertical-align: top;
  margin-right: 5px;
  display: inline-block;
  position: relative;
  top: -3px;
}

@media (forced-colors: active) {
  .govuk-header__logotype {
    forced-color-adjust: none;
    color: linktext;
  }
}

.govuk-header__logotype:last-child {
  margin-right: 0;
}

.govuk-header__product-name {
  vertical-align: top;
  margin-top: 10px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1;
  display: inline-table;
}

@media (width >= 40.0625em) {
  .govuk-header__product-name {
    font-size: 1.5rem;
    line-height: 1;
  }
}

@media print {
  .govuk-header__product-name {
    font-size: 18pt;
    line-height: 1;
  }
}

@-moz-document url-prefix() {
  .govuk-header__product-name {
    margin-top: 9.5px;
  }
}

@media (width >= 40.0625em) {
  .govuk-header__product-name {
    margin-top: 5px;
  }

  @-moz-document url-prefix() {
    .govuk-header__product-name {
      margin-top: 4.5px;
    }
  }
}

.govuk-header__link {
  text-decoration: none;
}

.govuk-header__link:link, .govuk-header__link:visited {
  color: #fff;
}

.govuk-header__link:hover, .govuk-header__link:active {
  color: #fffffffc;
}

.govuk-header__link:focus {
  color: #0b0c0c;
}

.govuk-header__link:hover {
  text-underline-offset: .1578em;
  text-decoration: underline 3px;
}

.govuk-header__link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-header__link--homepage {
  margin-right: 10px;
  font-size: 30px;
  display: inline-block;
}

@media (width >= 48.0625em) {
  .govuk-header__link--homepage {
    display: inline;
  }

  .govuk-header__link--homepage:focus {
    box-shadow: 0 0 #fd0;
  }
}

.govuk-header__link--homepage:link, .govuk-header__link--homepage:visited {
  text-decoration: none;
}

.govuk-header__link--homepage:hover, .govuk-header__link--homepage:active {
  border-bottom: 3px solid;
  margin-bottom: -3px;
}

.govuk-header__link--homepage:focus {
  border-bottom: 0;
  margin-bottom: 0;
}

.govuk-header__service-name {
  margin-bottom: 10px;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.11111;
  display: inline-block;
}

@media (width >= 40.0625em) {
  .govuk-header__service-name {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-header__service-name {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-header__logo, .govuk-header__content {
  box-sizing: border-box;
}

.govuk-header__logo {
  margin-bottom: 10px;
  padding-right: 80px;
}

@media (width >= 48.0625em) {
  .govuk-header__logo {
    float: left;
    vertical-align: top;
    width: 33.33%;
    padding-right: 15px;
  }

  .govuk-header__logo:last-child {
    float: none;
    width: auto;
    padding-right: 0;
  }

  .govuk-header__content {
    float: left;
    width: 66.66%;
    padding-left: 15px;
  }
}

.govuk-header__menu-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  word-break: break-all;
  cursor: pointer;
  background: none;
  border: 0;
  max-width: 80px;
  min-height: 24px;
  margin: 0;
  padding: 0;
  font-family: Inter, Arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
  position: absolute;
  top: 13px;
  right: 0;
}

@media print {
  .govuk-header__menu-button {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-header__menu-button {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-header__menu-button {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-header__menu-button:hover {
  text-underline-offset: .1578em;
  -webkit-text-decoration: underline 3px;
  text-decoration: underline 3px;
}

.govuk-header__menu-button:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-header__menu-button:after {
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  border: 5px solid #0000;
  border-width: 8.66px 5px 0;
  border-top-color: inherit;
  content: "";
  width: 0;
  height: 0;
  margin-left: 5px;
  display: inline-block;
}

.govuk-header__menu-button[aria-expanded="true"]:after {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  border: 5px solid #0000;
  border-width: 0 5px 8.66px;
  border-bottom-color: inherit;
  width: 0;
  height: 0;
  display: inline-block;
}

@media (width >= 40.0625em) {
  .govuk-header__menu-button {
    top: 15px;
  }
}

.govuk-frontend-supported .govuk-header__menu-button {
  display: block;
}

.govuk-header__menu-button[hidden], .govuk-frontend-supported .govuk-header__menu-button[hidden] {
  display: none;
}

@media (width >= 48.0625em) {
  .govuk-header__navigation {
    margin-bottom: 10px;
  }
}

.govuk-header__navigation-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.govuk-header__navigation-list[hidden] {
  display: none;
}

@media (width >= 48.0625em) {
  .govuk-header__navigation--end {
    text-align: right;
    margin: 0;
    padding: 5px 0;
  }
}

.govuk-header__navigation-item {
  border-bottom: 1px solid #2e3133;
  padding: 10px 0;
}

@media (width >= 48.0625em) {
  .govuk-header__navigation-item {
    border: 0;
    margin-right: 15px;
    padding: 5px 0;
    display: inline-block;
  }
}

.govuk-header__navigation-item a {
  white-space: nowrap;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.14286;
}

@media (width >= 40.0625em) {
  .govuk-header__navigation-item a {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-header__navigation-item a {
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-header__navigation-item--active a:link, .govuk-header__navigation-item--active a:hover, .govuk-header__navigation-item--active a:visited {
  color: #1d8feb;
}

@media print {
  .govuk-header__navigation-item--active a {
    color: #1d70b8;
  }
}

.govuk-header__navigation-item--active a:focus {
  color: #0b0c0c;
}

.govuk-header__navigation-item:last-child {
  border-bottom: 0;
  margin-right: 0;
}

@media print {
  .govuk-header {
    color: #0b0c0c;
    background: none;
    border-bottom-width: 0;
  }

  .govuk-header__link:link, .govuk-header__link:visited {
    color: #0b0c0c;
  }

  .govuk-header__link:after {
    display: none;
  }
}

.govuk-inset-text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  clear: both;
  border-left: 10px solid #b1b4b6;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-inset-text {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-inset-text {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-inset-text {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-inset-text {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.govuk-inset-text > :first-child {
  margin-top: 0;
}

.govuk-inset-text > :only-child, .govuk-inset-text > :last-child {
  margin-bottom: 0;
}

.govuk-notification-banner {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d70b8;
  border: 5px solid #1d70b8;
  margin-bottom: 30px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-notification-banner {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-notification-banner {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-notification-banner {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-notification-banner {
    margin-bottom: 50px;
  }
}

.govuk-notification-banner:focus {
  outline: 3px solid #fd0;
}

.govuk-notification-banner__header {
  border-bottom: 1px solid #0000;
  padding: 2px 15px 5px;
}

@media (width >= 40.0625em) {
  .govuk-notification-banner__header {
    padding: 2px 20px 5px;
  }
}

.govuk-notification-banner__title {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
}

@media (width >= 40.0625em) {
  .govuk-notification-banner__title {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-notification-banner__title {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-notification-banner__content {
  color: #0b0c0c;
  background-color: #fff;
  padding: 15px;
}

@media print {
  .govuk-notification-banner__content {
    color: #000;
  }
}

@media (width >= 40.0625em) {
  .govuk-notification-banner__content {
    padding: 20px;
  }
}

.govuk-notification-banner__content > * {
  box-sizing: border-box;
  max-width: 605px;
}

.govuk-notification-banner__content > :last-child {
  margin-bottom: 0;
}

.govuk-notification-banner__heading {
  margin: 0 0 15px;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.11111;
}

@media (width >= 40.0625em) {
  .govuk-notification-banner__heading {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-notification-banner__heading {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-notification-banner__link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-underline-offset: .1578em;
  font-family: Inter, Arial, sans-serif;
  text-decoration: underline max(1px, .0625rem);
}

@media print {
  .govuk-notification-banner__link {
    font-family: sans-serif;
  }
}

.govuk-notification-banner__link:hover {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration-skip: none;
  text-decoration-thickness: max(3px, .1875rem, .12em);
}

.govuk-notification-banner__link:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-notification-banner__link:link, .govuk-notification-banner__link:visited {
  color: #1d70b8;
}

.govuk-notification-banner__link:hover {
  color: #003078;
}

.govuk-notification-banner__link:active, .govuk-notification-banner__link:focus {
  color: #0b0c0c;
}

.govuk-notification-banner--success {
  background-color: #00703c;
  border-color: #00703c;
}

.govuk-notification-banner--success .govuk-notification-banner__link:link, .govuk-notification-banner--success .govuk-notification-banner__link:visited {
  color: #00703c;
}

.govuk-notification-banner--success .govuk-notification-banner__link:hover {
  color: #004e2a;
}

.govuk-notification-banner--success .govuk-notification-banner__link:active {
  color: #00703c;
}

.govuk-notification-banner--success .govuk-notification-banner__link:focus {
  color: #0b0c0c;
}

.govuk-pagination {
  flex-flow: column wrap;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

@media (width >= 40.0625em) {
  .govuk-pagination {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 30px;
  }
}

.govuk-pagination__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.govuk-pagination__item, .govuk-pagination__next, .govuk-pagination__prev {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  float: left;
  min-width: 45px;
  min-height: 45px;
  padding: 10px 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  position: relative;
}

@media print {
  .govuk-pagination__item, .govuk-pagination__next, .govuk-pagination__prev {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-pagination__item, .govuk-pagination__next, .govuk-pagination__prev {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-pagination__item, .govuk-pagination__next, .govuk-pagination__prev {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-pagination__item:hover, .govuk-pagination__next:hover, .govuk-pagination__prev:hover {
  background-color: #f3f2f1;
}

.govuk-pagination__item {
  text-align: center;
  display: none;
}

@media (width >= 40.0625em) {
  .govuk-pagination__item {
    display: block;
  }
}

.govuk-pagination__prev, .govuk-pagination__next {
  font-weight: 600;
}

.govuk-pagination__prev .govuk-pagination__link, .govuk-pagination__next .govuk-pagination__link {
  align-items: center;
  display: flex;
}

.govuk-pagination__prev {
  padding-left: 0;
}

.govuk-pagination__next {
  padding-right: 0;
}

.govuk-pagination__item--current, .govuk-pagination__item--ellipses, .govuk-pagination__item:first-child, .govuk-pagination__item:last-child {
  display: block;
}

.govuk-pagination__item--current {
  background-color: #1d70b8;
  outline: 1px solid #0000;
  font-weight: 600;
}

.govuk-pagination__item--current:hover {
  background-color: #1d70b8;
}

.govuk-pagination__item--current .govuk-pagination__link:link, .govuk-pagination__item--current .govuk-pagination__link:visited {
  color: #fff;
}

.govuk-pagination__item--current .govuk-pagination__link:hover, .govuk-pagination__item--current .govuk-pagination__link:active {
  color: #fffffffc;
}

.govuk-pagination__item--current .govuk-pagination__link:focus {
  color: #0b0c0c;
}

.govuk-pagination__item--ellipses {
  color: #505a5f;
  font-weight: 600;
}

.govuk-pagination__item--ellipses:hover {
  background-color: #0000;
}

.govuk-pagination__link {
  min-width: 15px;
  display: block;
}

@media screen {
  .govuk-pagination__link:after {
    content: "";
    position: absolute;
    inset: 0;
  }
}

.govuk-pagination__link:hover .govuk-pagination__link-title--decorated, .govuk-pagination__link:active .govuk-pagination__link-title--decorated {
  text-underline-offset: .1578em;
  text-decoration: underline max(1px, .0625rem);
}

.govuk-pagination__link:hover .govuk-pagination__link-label, .govuk-pagination__link:hover .govuk-pagination__link-title--decorated, .govuk-pagination__link:active .govuk-pagination__link-label, .govuk-pagination__link:active .govuk-pagination__link-title--decorated {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration-skip: none;
  text-decoration-thickness: max(3px, .1875rem, .12em);
}

.govuk-pagination__link:focus .govuk-pagination__icon {
  color: #0b0c0c;
}

.govuk-pagination__link:focus .govuk-pagination__link-label, .govuk-pagination__link:focus .govuk-pagination__link-title--decorated {
  text-decoration: none;
}

.govuk-pagination__link-label {
  text-underline-offset: .1578em;
  padding-left: 30px;
  font-weight: 400;
  text-decoration: underline max(1px, .0625rem);
  display: inline-block;
}

.govuk-pagination__icon {
  color: #505a5f;
  fill: currentColor;
  forced-color-adjust: auto;
  width: .9375rem;
  height: .8125rem;
}

.govuk-pagination__icon--prev {
  margin-right: 15px;
}

.govuk-pagination__icon--next {
  margin-left: 15px;
}

.govuk-pagination--block {
  display: block;
}

.govuk-pagination--block .govuk-pagination__item {
  float: none;
  padding: 15px;
}

.govuk-pagination--block .govuk-pagination__next, .govuk-pagination--block .govuk-pagination__prev {
  float: none;
  padding-left: 0;
}

.govuk-pagination--block .govuk-pagination__next {
  padding-right: 15px;
}

.govuk-pagination--block .govuk-pagination__next .govuk-pagination__icon {
  margin-left: 0;
}

.govuk-pagination--block .govuk-pagination__prev + .govuk-pagination__next {
  border-top: 1px solid #b1b4b6;
}

.govuk-pagination--block .govuk-pagination__link, .govuk-pagination--block .govuk-pagination__link-title {
  display: inline;
}

.govuk-pagination--block .govuk-pagination__link-title:after {
  content: "";
  display: block;
}

.govuk-pagination--block .govuk-pagination__link {
  text-align: left;
}

.govuk-pagination--block .govuk-pagination__link:focus .govuk-pagination__link-label {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-pagination--block .govuk-pagination__link:not(:focus) {
  text-decoration: none;
}

.govuk-pagination--block .govuk-pagination__icon {
  margin-right: 10px;
}

.govuk-panel {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-align: center;
  border: 5px solid #0000;
  margin-bottom: 15px;
  padding: 35px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.04167;
}

@media print {
  .govuk-panel {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-panel {
    font-size: 2.25rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-panel {
    font-size: 24pt;
    line-height: 1.05;
  }
}

@media (width <= 40.0525em) {
  .govuk-panel {
    overflow-wrap: break-word;
    word-wrap: break-word;
    padding: 10px;
  }
}

.govuk-panel--confirmation {
  color: #fff;
  background: #00703c;
}

@media print {
  .govuk-panel--confirmation {
    color: #000;
    background: none;
    border-color: currentColor;
  }
}

.govuk-panel__title {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.09375;
}

@media (width >= 40.0625em) {
  .govuk-panel__title {
    font-size: 3rem;
    line-height: 1.04167;
  }
}

@media print {
  .govuk-panel__title {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-panel__title:last-child {
  margin-bottom: 0;
}

@media (width >= 20em) {
  .govuk-password-input__wrapper {
    flex-direction: row;
    align-items: flex-start;
  }
}

.govuk-password-input__input::-ms-reveal {
  display: none;
}

.govuk-password-input__toggle {
  margin-top: 5px;
  margin-bottom: 0;
}

.govuk-password-input__toggle[hidden] {
  display: none;
}

@media (width >= 20em) {
  .govuk-password-input__toggle {
    flex-shrink: 0;
    flex-basis: 5em;
    width: auto;
    margin-top: 0;
    margin-left: 5px;
  }
}

.govuk-tag {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0c2d4a;
  overflow-wrap: break-word;
  background-color: #bbd4ea;
  max-width: 160px;
  margin-top: -2px;
  margin-bottom: -3px;
  padding: 2px 8px 3px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
  display: inline-block;
}

@media print {
  .govuk-tag {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-tag {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-tag {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media screen and (forced-colors: active) {
  .govuk-tag {
    font-weight: bold;
  }
}

.govuk-tag--grey {
  color: #282d30;
  background-color: #e5e6e7;
}

.govuk-tag--purple {
  color: #491644;
  background-color: #efdfed;
}

.govuk-tag--turquoise {
  color: #10403c;
  background-color: #d4ecea;
}

.govuk-tag--blue {
  color: #0c2d4a;
  background-color: #bbd4ea;
}

.govuk-tag--light-blue {
  color: #0c2d4a;
  background-color: #e8f1f8;
}

.govuk-tag--yellow {
  color: #594d00;
  background-color: #fff7bf;
}

.govuk-tag--orange {
  color: #6e3619;
  background-color: #fcd6c3;
}

.govuk-tag--red {
  color: #2a0b06;
  background-color: #f4cdc6;
}

.govuk-tag--pink {
  color: #6b1c40;
  background-color: #f9e1ec;
}

.govuk-tag--green {
  color: #005a30;
  background-color: #cce2d8;
}

.govuk-phase-banner {
  border-bottom: 1px solid #b1b4b6;
  padding-top: 10px;
  padding-bottom: 10px;
}

.govuk-phase-banner__content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.14286;
  display: table;
}

@media print {
  .govuk-phase-banner__content {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-phase-banner__content {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-phase-banner__content {
    color: #000;
    font-size: 14pt;
    line-height: 1.2;
  }
}

.govuk-phase-banner__content__tag {
  margin-right: 10px;
  font-size: .875rem;
  line-height: 1.14286;
}

@media (width >= 40.0625em) {
  .govuk-phase-banner__content__tag {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-phase-banner__content__tag {
    font-size: 14pt;
    line-height: 1.2;
  }
}

@media screen and (forced-colors: active) {
  .govuk-phase-banner__content__tag {
    font-weight: bold;
  }
}

.govuk-phase-banner__text {
  vertical-align: middle;
  display: table-cell;
}

.govuk-radios__item {
  flex-wrap: wrap;
  margin-bottom: 10px;
  display: flex;
  position: relative;
}

.govuk-radios__item:last-child, .govuk-radios__item:last-of-type {
  margin-bottom: 0;
}

.govuk-radios__input {
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  width: 44px;
  height: 44px;
  margin: 0;
}

.govuk-radios__label {
  cursor: pointer;
  touch-action: manipulation;
  align-self: center;
  max-width: calc(100% - 74px);
  margin-bottom: 0;
  padding: 7px 15px;
}

.govuk-radios__label:before {
  content: "";
  box-sizing: border-box;
  background: none;
  border: 2px solid;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.govuk-radios__label:after {
  content: "";
  opacity: 0;
  background: currentColor;
  border: 10px solid;
  border-radius: 50%;
  width: 0;
  height: 0;
  position: absolute;
  top: 12px;
  left: 12px;
}

.govuk-radios__hint {
  width: 100%;
  margin-top: -5px;
  padding-left: 59px;
  padding-right: 15px;
  display: block;
}

.govuk-label:not(.govuk-label--m):not(.govuk-label--l):not(.govuk-label--xl) + .govuk-radios__hint {
  margin-bottom: 0;
}

.govuk-radios__input:focus + .govuk-radios__label:before {
  outline-offset: 1px;
  border-width: 4px;
  outline: 3px solid #0000;
  box-shadow: 0 0 0 4px #fd0;
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .govuk-radios__input:focus + .govuk-radios__label:before {
    outline-color: highlight;
  }
}

.govuk-radios__input:checked + .govuk-radios__label:after {
  opacity: 1;
}

.govuk-radios__input:disabled, .govuk-radios__input:disabled + .govuk-radios__label {
  cursor: not-allowed;
}

.govuk-radios__input:disabled + .govuk-radios__label, .govuk-radios__input:disabled ~ .govuk-hint {
  opacity: .5;
}

@media (width >= 40.0625em) {
  .govuk-radios--inline {
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
  }

  .govuk-radios--inline .govuk-radios__item {
    margin-right: 20px;
  }
}

.govuk-radios__divider {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  text-align: center;
  width: 40px;
  margin-bottom: 10px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-radios__divider {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-radios__divider {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-radios__divider {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-radios__conditional {
  border-left: 4px solid #b1b4b6;
  margin-bottom: 15px;
  margin-left: 18px;
  padding-left: 33px;
}

@media (width >= 40.0625em) {
  .govuk-radios__conditional {
    margin-bottom: 20px;
  }
}

.govuk-frontend-supported .govuk-radios__conditional--hidden {
  display: none;
}

.govuk-radios__conditional > :last-child, .govuk-radios--small .govuk-radios__item {
  margin-bottom: 0;
}

.govuk-radios--small .govuk-radios__input {
  margin-left: -10px;
}

.govuk-radios--small .govuk-radios__label {
  padding-left: 1px;
}

.govuk-radios--small .govuk-radios__label:before {
  width: 24px;
  height: 24px;
  top: 10px;
  left: 0;
}

.govuk-radios--small .govuk-radios__label:after {
  border-width: 5px;
  top: 17px;
  left: 7px;
}

.govuk-radios--small .govuk-radios__hint {
  padding-left: 34px;
}

.govuk-radios--small .govuk-radios__conditional {
  margin-left: 10px;
  padding-left: 20px;
}

.govuk-radios--small .govuk-radios__divider {
  width: 24px;
  margin-bottom: 5px;
}

.govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:not(:disabled) + .govuk-radios__label:before {
  outline-offset: 1px;
  outline: 4px dashed #0000;
  box-shadow: 0 0 0 10px #b1b4b6;
}

.govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:focus + .govuk-radios__label:before {
  box-shadow: 0 0 0 4px #fd0 0 0 0 10px #b1b4b6;
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:focus + .govuk-radios__label:before {
    outline-color: highlight;
  }
}

@media (hover: none), (pointer: coarse) {
  .govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:not(:disabled) + .govuk-radios__label:before {
    box-shadow: initial;
  }

  .govuk-radios--small .govuk-radios__item:hover .govuk-radios__input:focus + .govuk-radios__label:before {
    box-shadow: 0 0 0 4px #fd0;
  }
}

.govuk-select {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #0b0c0c;
  background-color: #fff;
  border: 2px solid #0b0c0c;
  min-width: 11.5em;
  max-width: 100%;
  height: 2.5rem;
  padding: 5px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-select {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-select {
    font-size: 1.1875rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-select {
    font-size: 14pt;
    line-height: 1.25;
  }
}

.govuk-select:focus {
  outline-offset: 0;
  outline: 3px solid #fd0;
  box-shadow: inset 0 0 0 2px;
}

.govuk-select:disabled {
  opacity: .5;
  color: inherit;
  cursor: not-allowed;
}

.govuk-select option:active, .govuk-select option:checked {
  color: #fff;
  background-color: #1d70b8;
}

.govuk-select:focus::-ms-value {
  color: #fff;
  background-color: #1d70b8;
}

.govuk-select--error {
  border-color: #d4351c;
}

.govuk-select--error:focus {
  border-color: #0b0c0c;
}

.govuk-skip-link {
  -webkit-user-select: none;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-underline-offset: .1578em;
  padding: 10px 15px;
  font-family: Inter, Arial, sans-serif;
  font-size: .875rem;
  line-height: 1.14286;
  text-decoration: underline max(1px, .0625rem);
  display: block;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.govuk-skip-link:active, .govuk-skip-link:focus {
  -webkit-user-select: text;
  user-select: text;
  margin: inherit !important;
  clip: auto !important;
  clip-path: none !important;
  white-space: inherit !important;
  width: auto !important;
  height: auto !important;
  position: static !important;
  overflow: visible !important;
}

@media print {
  .govuk-skip-link {
    font-family: sans-serif;
  }
}

.govuk-skip-link:link, .govuk-skip-link:visited {
  color: #0b0c0c;
}

@media print {
  .govuk-skip-link:link, .govuk-skip-link:visited {
    color: #000;
  }
}

.govuk-skip-link:hover {
  color: #0b0c0cfc;
}

.govuk-skip-link:active, .govuk-skip-link:focus {
  color: #0b0c0c;
}

@media print {
  .govuk-skip-link:active, .govuk-skip-link:focus {
    color: #000;
  }
}

@media (width >= 40.0625em) {
  .govuk-skip-link {
    font-size: 1rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-skip-link {
    font-size: 14pt;
    line-height: 1.2;
  }
}

@supports (padding: max(calc(0px))) {
  .govuk-skip-link {
    padding-right: max(15px, calc(15px + env(safe-area-inset-right)));
    padding-left: max(15px, calc(15px + env(safe-area-inset-left)));
  }
}

.govuk-skip-link:focus {
  outline-offset: 0;
  background-color: #fd0;
  outline: 3px solid #fd0;
}

.govuk-skip-link-focused-element:focus {
  outline: none;
}

.govuk-summary-list {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin: 0 0 20px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-summary-list {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-summary-list {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-summary-list {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-summary-list {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 30px;
    display: table;
  }
}

.govuk-summary-list__row {
  border-bottom: 1px solid #b1b4b6;
}

@media (width <= 40.0525em) {
  .govuk-summary-list__row {
    margin-bottom: 15px;
  }
}

@media (width >= 40.0625em) {
  .govuk-summary-list__row {
    display: table-row;
  }
}

.govuk-summary-list__row:not(.govuk-summary-list__row--no-actions) > :last-child {
  padding-right: 0;
}

@media (width >= 40.0625em) {
  .govuk-summary-list__row--no-actions:after {
    content: "";
    width: 20%;
    display: table-cell;
  }
}

.govuk-summary-list__key, .govuk-summary-list__value, .govuk-summary-list__actions {
  margin: 0;
}

@media (width >= 40.0625em) {
  .govuk-summary-list__key, .govuk-summary-list__value, .govuk-summary-list__actions {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    display: table-cell;
  }
}

.govuk-summary-list__actions {
  margin-bottom: 15px;
}

@media (width >= 40.0625em) {
  .govuk-summary-list__actions {
    text-align: right;
    width: 20%;
  }
}

.govuk-summary-list__key, .govuk-summary-list__value {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.govuk-summary-list__key {
  margin-bottom: 5px;
  font-weight: 600;
}

@media (width >= 40.0625em) {
  .govuk-summary-list__key {
    width: 30%;
  }
}

@media (width <= 40.0525em) {
  .govuk-summary-list__value {
    margin-bottom: 15px;
  }
}

.govuk-summary-list__value > p {
  margin-bottom: 10px;
}

.govuk-summary-list__value > :last-child {
  margin-bottom: 0;
}

.govuk-summary-list__actions-list {
  width: 100%;
  margin: 0;
  padding: 0;
}

.govuk-summary-list__actions-list-item {
  display: inline-block;
}

@media (width <= 40.0525em) {
  .govuk-summary-list__actions-list-item {
    border-right: 1px solid #b1b4b6;
    margin-right: 10px;
    padding-right: 10px;
  }

  .govuk-summary-list__actions-list-item:last-child {
    border: 0;
    margin-right: 0;
    padding-right: 0;
  }
}

@media (width >= 40.0625em) {
  .govuk-summary-list__actions-list-item {
    margin-left: 10px;
    padding-left: 10px;
  }

  .govuk-summary-list__actions-list-item:not(:first-child) {
    border-left: 1px solid #b1b4b6;
  }

  .govuk-summary-list__actions-list-item:first-child {
    border: 0;
    margin-left: 0;
    padding-left: 0;
  }
}

.govuk-summary-list__actions-list-item .govuk-link:focus {
  isolation: isolate;
}

.govuk-summary-list--no-border .govuk-summary-list__row {
  border: 0;
}

@media (width >= 40.0625em) {
  .govuk-summary-list--no-border .govuk-summary-list__key, .govuk-summary-list--no-border .govuk-summary-list__value, .govuk-summary-list--no-border .govuk-summary-list__actions {
    padding-bottom: 11px;
  }
}

.govuk-summary-list__row--no-border {
  border: 0;
}

@media (width >= 40.0625em) {
  .govuk-summary-list__row--no-border .govuk-summary-list__key, .govuk-summary-list__row--no-border .govuk-summary-list__value, .govuk-summary-list__row--no-border .govuk-summary-list__actions {
    padding-bottom: 11px;
  }
}

.govuk-summary-card {
  border: 1px solid #b1b4b6;
  margin-bottom: 20px;
}

@media (width >= 40.0625em) {
  .govuk-summary-card {
    margin-bottom: 30px;
  }
}

.govuk-summary-card__title-wrapper {
  background-color: #f3f2f1;
  border-bottom: 1px solid #0000;
  padding: 15px;
}

@media (width >= 40.0625em) {
  .govuk-summary-card__title-wrapper {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 15px 20px;
    display: flex;
  }
}

.govuk-summary-card__title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  margin: 5px 20px 10px 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
}

@media print {
  .govuk-summary-card__title {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-summary-card__title {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-summary-card__title {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-summary-card__title {
    margin-bottom: 5px;
  }
}

.govuk-summary-card__actions {
  flex-wrap: wrap;
  row-gap: 10px;
  margin: 5px 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  list-style: none;
  display: flex;
}

@media (width >= 40.0625em) {
  .govuk-summary-card__actions {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-summary-card__actions {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-summary-card__actions {
    text-align: right;
    justify-content: right;
  }
}

.govuk-summary-card__action {
  border-right: 1px solid #b1b4b6;
  margin: 0 10px 0 0;
  padding-right: 10px;
  display: inline;
}

@media (width >= 40.0625em) {
  .govuk-summary-card__action {
    margin-right: 0;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .govuk-summary-card__action {
    margin-bottom: 5px;
  }
}

.govuk-summary-card__action:last-child {
  border-right: none;
  margin: 0;
  padding-right: 0;
}

@media (width >= 40.0625em) {
  .govuk-summary-card__action:last-child {
    padding-left: 10px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .govuk-summary-card__action:last-child {
    margin-bottom: 0;
  }
}

.govuk-summary-card__content {
  padding: 15px 15px 0;
}

@media (width >= 40.0625em) {
  .govuk-summary-card__content {
    padding: 15px 20px;
  }
}

.govuk-summary-card__content .govuk-summary-list {
  margin-bottom: 0;
}

.govuk-summary-card__content .govuk-summary-list__row:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
}

.govuk-table {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0b0c0c;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media print {
  .govuk-table {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-table {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-table {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-table {
    margin-bottom: 30px;
  }
}

.govuk-table__header {
  font-weight: 600;
}

.govuk-table__header, .govuk-table__cell {
  text-align: left;
  vertical-align: top;
  border-bottom: 1px solid #b1b4b6;
  padding: 10px 20px 10px 0;
}

.govuk-table__cell--numeric {
  font-variant-numeric: tabular-nums;
}

.govuk-table__header--numeric, .govuk-table__cell--numeric {
  text-align: right;
}

.govuk-table__header:last-child, .govuk-table__cell:last-child {
  padding-right: 0;
}

.govuk-table__caption {
  text-align: left;
  font-weight: 600;
  display: table-caption;
}

.govuk-table__caption--xl, .govuk-table__caption--l, .govuk-table__caption--m {
  margin-bottom: 15px;
}

.govuk-table__caption--xl {
  font-size: 2rem;
  line-height: 1.09375;
}

@media (width >= 40.0625em) {
  .govuk-table__caption--xl {
    font-size: 3rem;
    line-height: 1.04167;
  }
}

@media print {
  .govuk-table__caption--xl {
    font-size: 32pt;
    line-height: 1.15;
  }
}

.govuk-table__caption--l {
  font-size: 1.5rem;
  line-height: 1.04167;
}

@media (width >= 40.0625em) {
  .govuk-table__caption--l {
    font-size: 2.25rem;
    line-height: 1.11111;
  }
}

@media print {
  .govuk-table__caption--l {
    font-size: 24pt;
    line-height: 1.05;
  }
}

.govuk-table__caption--m {
  font-size: 1.125rem;
  line-height: 1.11111;
}

@media (width >= 40.0625em) {
  .govuk-table__caption--m {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

@media print {
  .govuk-table__caption--m {
    font-size: 18pt;
    line-height: 1.15;
  }
}

.govuk-tabs {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 5px;
  margin-bottom: 20px;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media (width >= 40.0625em) {
  .govuk-tabs {
    margin-bottom: 30px;
  }
}

@media print {
  .govuk-tabs {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-tabs {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-tabs {
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-tabs__title {
  color: #0b0c0c;
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media (width >= 40.0625em) {
  .govuk-tabs__title {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-tabs__title {
    color: #000;
    font-size: 14pt;
    line-height: 1.15;
  }
}

.govuk-tabs__list {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}

@media (width >= 40.0625em) {
  .govuk-tabs__list {
    margin-bottom: 30px;
  }
}

.govuk-tabs__list-item {
  margin-left: 25px;
}

.govuk-tabs__list-item:before {
  color: #0b0c0c;
  content: "—";
  margin-left: -25px;
  padding-right: 5px;
}

@media print {
  .govuk-tabs__list-item:before {
    color: #000;
  }
}

.govuk-tabs__tab {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-underline-offset: .1578em;
  margin-bottom: 10px;
  font-family: Inter, Arial, sans-serif;
  text-decoration: underline max(1px, .0625rem);
  display: inline-block;
}

@media print {
  .govuk-tabs__tab {
    font-family: sans-serif;
  }
}

.govuk-tabs__tab:hover {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration-skip: none;
  text-decoration-thickness: max(3px, .1875rem, .12em);
}

.govuk-tabs__tab:focus {
  color: #0b0c0c;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #fd0;
  outline: 3px solid #0000;
  text-decoration: none;
  box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
}

.govuk-tabs__tab:link {
  color: #1d70b8;
}

.govuk-tabs__tab:visited {
  color: #4c2c92;
}

.govuk-tabs__tab:hover {
  color: #003078;
}

.govuk-tabs__tab:active, .govuk-tabs__tab:focus {
  color: #0b0c0c;
}

.govuk-tabs__panel {
  margin-bottom: 30px;
}

@media (width >= 40.0625em) {
  .govuk-tabs__panel {
    margin-bottom: 50px;
  }

  .govuk-frontend-supported .govuk-tabs__list {
    border-bottom: 1px solid #b1b4b6;
    margin-bottom: 0;
  }

  .govuk-frontend-supported .govuk-tabs__list:after {
    content: "";
    clear: both;
    display: block;
  }

  .govuk-frontend-supported .govuk-tabs__title {
    display: none;
  }

  .govuk-frontend-supported .govuk-tabs__list-item {
    float: left;
    text-align: center;
    background-color: #f3f2f1;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 5px;
    padding: 10px 20px;
    position: relative;
  }

  .govuk-frontend-supported .govuk-tabs__list-item:before {
    content: none;
  }

  .govuk-frontend-supported .govuk-tabs__list-item--selected {
    background-color: #fff;
    border: 1px solid #b1b4b6;
    border-bottom: 0;
    margin-top: -5px;
    margin-bottom: -1px;
    padding: 14px 19px 16px;
    position: relative;
  }

  .govuk-frontend-supported .govuk-tabs__list-item--selected .govuk-tabs__tab {
    text-decoration: none;
  }

  .govuk-frontend-supported .govuk-tabs__tab {
    margin-bottom: 0;
  }

  .govuk-frontend-supported .govuk-tabs__tab:link, .govuk-frontend-supported .govuk-tabs__tab:visited {
    color: #0b0c0c;
  }
}

@media print and (width >= 40.0625em) {
  .govuk-frontend-supported .govuk-tabs__tab:link, .govuk-frontend-supported .govuk-tabs__tab:visited {
    color: #000;
  }
}

@media (width >= 40.0625em) {
  .govuk-frontend-supported .govuk-tabs__tab:hover {
    color: #0b0c0cfc;
  }

  .govuk-frontend-supported .govuk-tabs__tab:active, .govuk-frontend-supported .govuk-tabs__tab:focus {
    color: #0b0c0c;
  }
}

@media print and (width >= 40.0625em) {
  .govuk-frontend-supported .govuk-tabs__tab:active, .govuk-frontend-supported .govuk-tabs__tab:focus {
    color: #000;
  }
}

@media (width >= 40.0625em) {
  .govuk-frontend-supported .govuk-tabs__tab:after {
    content: "";
    position: absolute;
    inset: 0;
  }

  .govuk-frontend-supported .govuk-tabs__panel {
    border: 1px solid #b1b4b6;
    border-top: 0;
    margin-bottom: 0;
    padding: 30px 20px;
  }

  .govuk-frontend-supported .govuk-tabs__panel > :last-child {
    margin-bottom: 0;
  }

  .govuk-frontend-supported .govuk-tabs__panel--hidden {
    display: none;
  }
}

.govuk-task-list {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 20px;
  padding: 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  list-style-type: none;
}

@media print {
  .govuk-task-list {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-task-list {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-task-list {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-task-list {
    margin-bottom: 30px;
  }
}

.govuk-task-list__item {
  border-bottom: 1px solid #b1b4b6;
  width: 100%;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  display: table;
  position: relative;
}

.govuk-task-list__item:first-child {
  border-top: 1px solid #b1b4b6;
}

.govuk-task-list__item--with-link:hover {
  background: #f3f2f1;
}

.govuk-task-list__name-and-hint {
  vertical-align: top;
  color: #0b0c0c;
  display: table-cell;
}

@media print {
  .govuk-task-list__name-and-hint {
    color: #000;
  }
}

.govuk-task-list__status {
  text-align: right;
  vertical-align: top;
  color: #0b0c0c;
  padding-left: 10px;
  display: table-cell;
}

@media print {
  .govuk-task-list__status {
    color: #000;
  }
}

.govuk-task-list__status--cannot-start-yet {
  color: #505a5f;
}

.govuk-task-list__link:after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
}

.govuk-task-list__hint {
  color: #505a5f;
  margin-top: 5px;
}

.govuk-warning-text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 20px;
  padding: 10px 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  position: relative;
}

@media print {
  .govuk-warning-text {
    font-family: sans-serif;
  }
}

@media (width >= 40.0625em) {
  .govuk-warning-text {
    font-size: 1.1875rem;
    line-height: 1.31579;
  }
}

@media print {
  .govuk-warning-text {
    font-size: 14pt;
    line-height: 1.15;
  }
}

@media (width >= 40.0625em) {
  .govuk-warning-text {
    margin-bottom: 30px;
  }
}

.govuk-warning-text__icon {
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  forced-color-adjust: none;
  background: #0b0c0c;
  border: 3px solid #0b0c0c;
  border-radius: 50%;
  min-width: 35px;
  min-height: 35px;
  margin-top: -7px;
  font-size: 30px;
  font-weight: 600;
  line-height: 29px;
  display: inline-block;
  position: absolute;
  left: 0;
}

@media (width >= 40.0625em) {
  .govuk-warning-text__icon {
    margin-top: -5px;
  }
}

@media screen and (forced-colors: active) {
  .govuk-warning-text__icon {
    color: windowtext;
    background: none;
    border-color: windowtext;
  }
}

.govuk-warning-text__text {
  color: #0b0c0c;
  padding-left: 45px;
  display: block;
}

@media print {
  .govuk-warning-text__text {
    color: #000;
  }
}

.govuk-clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.govuk-visually-hidden {
  -webkit-user-select: none;
  user-select: none;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.govuk-visually-hidden:before, .govuk-visually-hidden:after {
  content: " ";
}

.govuk-visually-hidden-focusable {
  -webkit-user-select: none;
  user-select: none;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.govuk-visually-hidden-focusable:active, .govuk-visually-hidden-focusable:focus {
  -webkit-user-select: text;
  user-select: text;
  margin: inherit !important;
  clip: auto !important;
  clip-path: none !important;
  white-space: inherit !important;
  width: auto !important;
  height: auto !important;
  position: static !important;
  overflow: visible !important;
}

.govuk-\!-display-inline {
  display: inline !important;
}

.govuk-\!-display-inline-block {
  display: inline-block !important;
}

.govuk-\!-display-block {
  display: block !important;
}

.govuk-\!-display-none {
  display: none !important;
}

@media print {
  .govuk-\!-display-none-print {
    display: none !important;
  }
}

.govuk-\!-margin-0 {
  margin: 0 !important;
}

.govuk-\!-margin-top-0 {
  margin-top: 0 !important;
}

.govuk-\!-margin-right-0 {
  margin-right: 0 !important;
}

.govuk-\!-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.govuk-\!-margin-left-0 {
  margin-left: 0 !important;
}

.govuk-\!-margin-1 {
  margin: 5px !important;
}

.govuk-\!-margin-top-1 {
  margin-top: 5px !important;
}

.govuk-\!-margin-right-1 {
  margin-right: 5px !important;
}

.govuk-\!-margin-bottom-1 {
  margin-bottom: 5px !important;
}

.govuk-\!-margin-left-1 {
  margin-left: 5px !important;
}

.govuk-\!-margin-2 {
  margin: 10px !important;
}

.govuk-\!-margin-top-2 {
  margin-top: 10px !important;
}

.govuk-\!-margin-right-2 {
  margin-right: 10px !important;
}

.govuk-\!-margin-bottom-2 {
  margin-bottom: 10px !important;
}

.govuk-\!-margin-left-2 {
  margin-left: 10px !important;
}

.govuk-\!-margin-3 {
  margin: 15px !important;
}

.govuk-\!-margin-top-3 {
  margin-top: 15px !important;
}

.govuk-\!-margin-right-3 {
  margin-right: 15px !important;
}

.govuk-\!-margin-bottom-3 {
  margin-bottom: 15px !important;
}

.govuk-\!-margin-left-3 {
  margin-left: 15px !important;
}

.govuk-\!-margin-4 {
  margin: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-4 {
    margin: 20px !important;
  }
}

.govuk-\!-margin-top-4 {
  margin-top: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-top-4 {
    margin-top: 20px !important;
  }
}

.govuk-\!-margin-right-4 {
  margin-right: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-right-4 {
    margin-right: 20px !important;
  }
}

.govuk-\!-margin-bottom-4 {
  margin-bottom: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-bottom-4 {
    margin-bottom: 20px !important;
  }
}

.govuk-\!-margin-left-4 {
  margin-left: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-left-4 {
    margin-left: 20px !important;
  }
}

.govuk-\!-margin-5 {
  margin: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-5 {
    margin: 25px !important;
  }
}

.govuk-\!-margin-top-5 {
  margin-top: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-top-5 {
    margin-top: 25px !important;
  }
}

.govuk-\!-margin-right-5 {
  margin-right: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-right-5 {
    margin-right: 25px !important;
  }
}

.govuk-\!-margin-bottom-5 {
  margin-bottom: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-bottom-5 {
    margin-bottom: 25px !important;
  }
}

.govuk-\!-margin-left-5 {
  margin-left: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-left-5 {
    margin-left: 25px !important;
  }
}

.govuk-\!-margin-6 {
  margin: 20px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-6 {
    margin: 30px !important;
  }
}

.govuk-\!-margin-top-6 {
  margin-top: 20px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-top-6 {
    margin-top: 30px !important;
  }
}

.govuk-\!-margin-right-6 {
  margin-right: 20px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-right-6 {
    margin-right: 30px !important;
  }
}

.govuk-\!-margin-bottom-6 {
  margin-bottom: 20px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-bottom-6 {
    margin-bottom: 30px !important;
  }
}

.govuk-\!-margin-left-6 {
  margin-left: 20px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-left-6 {
    margin-left: 30px !important;
  }
}

.govuk-\!-margin-7 {
  margin: 25px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-7 {
    margin: 40px !important;
  }
}

.govuk-\!-margin-top-7 {
  margin-top: 25px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-top-7 {
    margin-top: 40px !important;
  }
}

.govuk-\!-margin-right-7 {
  margin-right: 25px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-right-7 {
    margin-right: 40px !important;
  }
}

.govuk-\!-margin-bottom-7 {
  margin-bottom: 25px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-bottom-7 {
    margin-bottom: 40px !important;
  }
}

.govuk-\!-margin-left-7 {
  margin-left: 25px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-left-7 {
    margin-left: 40px !important;
  }
}

.govuk-\!-margin-8 {
  margin: 30px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-8 {
    margin: 50px !important;
  }
}

.govuk-\!-margin-top-8 {
  margin-top: 30px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-top-8 {
    margin-top: 50px !important;
  }
}

.govuk-\!-margin-right-8 {
  margin-right: 30px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-right-8 {
    margin-right: 50px !important;
  }
}

.govuk-\!-margin-bottom-8 {
  margin-bottom: 30px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-bottom-8 {
    margin-bottom: 50px !important;
  }
}

.govuk-\!-margin-left-8 {
  margin-left: 30px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-left-8 {
    margin-left: 50px !important;
  }
}

.govuk-\!-margin-9 {
  margin: 40px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-9 {
    margin: 60px !important;
  }
}

.govuk-\!-margin-top-9 {
  margin-top: 40px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-top-9 {
    margin-top: 60px !important;
  }
}

.govuk-\!-margin-right-9 {
  margin-right: 40px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-right-9 {
    margin-right: 60px !important;
  }
}

.govuk-\!-margin-bottom-9 {
  margin-bottom: 40px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-bottom-9 {
    margin-bottom: 60px !important;
  }
}

.govuk-\!-margin-left-9 {
  margin-left: 40px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-margin-left-9 {
    margin-left: 60px !important;
  }
}

.govuk-\!-padding-0 {
  padding: 0 !important;
}

.govuk-\!-padding-top-0 {
  padding-top: 0 !important;
}

.govuk-\!-padding-right-0 {
  padding-right: 0 !important;
}

.govuk-\!-padding-bottom-0 {
  padding-bottom: 0 !important;
}

.govuk-\!-padding-left-0 {
  padding-left: 0 !important;
}

.govuk-\!-padding-1 {
  padding: 5px !important;
}

.govuk-\!-padding-top-1 {
  padding-top: 5px !important;
}

.govuk-\!-padding-right-1 {
  padding-right: 5px !important;
}

.govuk-\!-padding-bottom-1 {
  padding-bottom: 5px !important;
}

.govuk-\!-padding-left-1 {
  padding-left: 5px !important;
}

.govuk-\!-padding-2 {
  padding: 10px !important;
}

.govuk-\!-padding-top-2 {
  padding-top: 10px !important;
}

.govuk-\!-padding-right-2 {
  padding-right: 10px !important;
}

.govuk-\!-padding-bottom-2 {
  padding-bottom: 10px !important;
}

.govuk-\!-padding-left-2 {
  padding-left: 10px !important;
}

.govuk-\!-padding-3 {
  padding: 15px !important;
}

.govuk-\!-padding-top-3 {
  padding-top: 15px !important;
}

.govuk-\!-padding-right-3 {
  padding-right: 15px !important;
}

.govuk-\!-padding-bottom-3 {
  padding-bottom: 15px !important;
}

.govuk-\!-padding-left-3 {
  padding-left: 15px !important;
}

.govuk-\!-padding-4 {
  padding: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-4 {
    padding: 20px !important;
  }
}

.govuk-\!-padding-top-4 {
  padding-top: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-top-4 {
    padding-top: 20px !important;
  }
}

.govuk-\!-padding-right-4 {
  padding-right: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-right-4 {
    padding-right: 20px !important;
  }
}

.govuk-\!-padding-bottom-4 {
  padding-bottom: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-bottom-4 {
    padding-bottom: 20px !important;
  }
}

.govuk-\!-padding-left-4 {
  padding-left: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-left-4 {
    padding-left: 20px !important;
  }
}

.govuk-\!-padding-5 {
  padding: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-5 {
    padding: 25px !important;
  }
}

.govuk-\!-padding-top-5 {
  padding-top: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-top-5 {
    padding-top: 25px !important;
  }
}

.govuk-\!-padding-right-5 {
  padding-right: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-right-5 {
    padding-right: 25px !important;
  }
}

.govuk-\!-padding-bottom-5 {
  padding-bottom: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-bottom-5 {
    padding-bottom: 25px !important;
  }
}

.govuk-\!-padding-left-5 {
  padding-left: 15px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-left-5 {
    padding-left: 25px !important;
  }
}

.govuk-\!-padding-6 {
  padding: 20px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-6 {
    padding: 30px !important;
  }
}

.govuk-\!-padding-top-6 {
  padding-top: 20px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-top-6 {
    padding-top: 30px !important;
  }
}

.govuk-\!-padding-right-6 {
  padding-right: 20px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-right-6 {
    padding-right: 30px !important;
  }
}

.govuk-\!-padding-bottom-6 {
  padding-bottom: 20px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-bottom-6 {
    padding-bottom: 30px !important;
  }
}

.govuk-\!-padding-left-6 {
  padding-left: 20px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-left-6 {
    padding-left: 30px !important;
  }
}

.govuk-\!-padding-7 {
  padding: 25px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-7 {
    padding: 40px !important;
  }
}

.govuk-\!-padding-top-7 {
  padding-top: 25px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-top-7 {
    padding-top: 40px !important;
  }
}

.govuk-\!-padding-right-7 {
  padding-right: 25px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-right-7 {
    padding-right: 40px !important;
  }
}

.govuk-\!-padding-bottom-7 {
  padding-bottom: 25px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-bottom-7 {
    padding-bottom: 40px !important;
  }
}

.govuk-\!-padding-left-7 {
  padding-left: 25px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-left-7 {
    padding-left: 40px !important;
  }
}

.govuk-\!-padding-8 {
  padding: 30px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-8 {
    padding: 50px !important;
  }
}

.govuk-\!-padding-top-8 {
  padding-top: 30px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-top-8 {
    padding-top: 50px !important;
  }
}

.govuk-\!-padding-right-8 {
  padding-right: 30px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-right-8 {
    padding-right: 50px !important;
  }
}

.govuk-\!-padding-bottom-8 {
  padding-bottom: 30px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-bottom-8 {
    padding-bottom: 50px !important;
  }
}

.govuk-\!-padding-left-8 {
  padding-left: 30px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-left-8 {
    padding-left: 50px !important;
  }
}

.govuk-\!-padding-9 {
  padding: 40px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-9 {
    padding: 60px !important;
  }
}

.govuk-\!-padding-top-9 {
  padding-top: 40px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-top-9 {
    padding-top: 60px !important;
  }
}

.govuk-\!-padding-right-9 {
  padding-right: 40px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-right-9 {
    padding-right: 60px !important;
  }
}

.govuk-\!-padding-bottom-9 {
  padding-bottom: 40px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-bottom-9 {
    padding-bottom: 60px !important;
  }
}

.govuk-\!-padding-left-9 {
  padding-left: 40px !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-padding-left-9 {
    padding-left: 60px !important;
  }
}

.govuk-\!-static-margin-0 {
  margin: 0 !important;
}

.govuk-\!-static-margin-top-0 {
  margin-top: 0 !important;
}

.govuk-\!-static-margin-right-0 {
  margin-right: 0 !important;
}

.govuk-\!-static-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.govuk-\!-static-margin-left-0 {
  margin-left: 0 !important;
}

.govuk-\!-static-margin-1 {
  margin: 5px !important;
}

.govuk-\!-static-margin-top-1 {
  margin-top: 5px !important;
}

.govuk-\!-static-margin-right-1 {
  margin-right: 5px !important;
}

.govuk-\!-static-margin-bottom-1 {
  margin-bottom: 5px !important;
}

.govuk-\!-static-margin-left-1 {
  margin-left: 5px !important;
}

.govuk-\!-static-margin-2 {
  margin: 10px !important;
}

.govuk-\!-static-margin-top-2 {
  margin-top: 10px !important;
}

.govuk-\!-static-margin-right-2 {
  margin-right: 10px !important;
}

.govuk-\!-static-margin-bottom-2 {
  margin-bottom: 10px !important;
}

.govuk-\!-static-margin-left-2 {
  margin-left: 10px !important;
}

.govuk-\!-static-margin-3 {
  margin: 15px !important;
}

.govuk-\!-static-margin-top-3 {
  margin-top: 15px !important;
}

.govuk-\!-static-margin-right-3 {
  margin-right: 15px !important;
}

.govuk-\!-static-margin-bottom-3 {
  margin-bottom: 15px !important;
}

.govuk-\!-static-margin-left-3 {
  margin-left: 15px !important;
}

.govuk-\!-static-margin-4 {
  margin: 20px !important;
}

.govuk-\!-static-margin-top-4 {
  margin-top: 20px !important;
}

.govuk-\!-static-margin-right-4 {
  margin-right: 20px !important;
}

.govuk-\!-static-margin-bottom-4 {
  margin-bottom: 20px !important;
}

.govuk-\!-static-margin-left-4 {
  margin-left: 20px !important;
}

.govuk-\!-static-margin-5 {
  margin: 25px !important;
}

.govuk-\!-static-margin-top-5 {
  margin-top: 25px !important;
}

.govuk-\!-static-margin-right-5 {
  margin-right: 25px !important;
}

.govuk-\!-static-margin-bottom-5 {
  margin-bottom: 25px !important;
}

.govuk-\!-static-margin-left-5 {
  margin-left: 25px !important;
}

.govuk-\!-static-margin-6 {
  margin: 30px !important;
}

.govuk-\!-static-margin-top-6 {
  margin-top: 30px !important;
}

.govuk-\!-static-margin-right-6 {
  margin-right: 30px !important;
}

.govuk-\!-static-margin-bottom-6 {
  margin-bottom: 30px !important;
}

.govuk-\!-static-margin-left-6 {
  margin-left: 30px !important;
}

.govuk-\!-static-margin-7 {
  margin: 40px !important;
}

.govuk-\!-static-margin-top-7 {
  margin-top: 40px !important;
}

.govuk-\!-static-margin-right-7 {
  margin-right: 40px !important;
}

.govuk-\!-static-margin-bottom-7 {
  margin-bottom: 40px !important;
}

.govuk-\!-static-margin-left-7 {
  margin-left: 40px !important;
}

.govuk-\!-static-margin-8 {
  margin: 50px !important;
}

.govuk-\!-static-margin-top-8 {
  margin-top: 50px !important;
}

.govuk-\!-static-margin-right-8 {
  margin-right: 50px !important;
}

.govuk-\!-static-margin-bottom-8 {
  margin-bottom: 50px !important;
}

.govuk-\!-static-margin-left-8 {
  margin-left: 50px !important;
}

.govuk-\!-static-margin-9 {
  margin: 60px !important;
}

.govuk-\!-static-margin-top-9 {
  margin-top: 60px !important;
}

.govuk-\!-static-margin-right-9 {
  margin-right: 60px !important;
}

.govuk-\!-static-margin-bottom-9 {
  margin-bottom: 60px !important;
}

.govuk-\!-static-margin-left-9 {
  margin-left: 60px !important;
}

.govuk-\!-static-padding-0 {
  padding: 0 !important;
}

.govuk-\!-static-padding-top-0 {
  padding-top: 0 !important;
}

.govuk-\!-static-padding-right-0 {
  padding-right: 0 !important;
}

.govuk-\!-static-padding-bottom-0 {
  padding-bottom: 0 !important;
}

.govuk-\!-static-padding-left-0 {
  padding-left: 0 !important;
}

.govuk-\!-static-padding-1 {
  padding: 5px !important;
}

.govuk-\!-static-padding-top-1 {
  padding-top: 5px !important;
}

.govuk-\!-static-padding-right-1 {
  padding-right: 5px !important;
}

.govuk-\!-static-padding-bottom-1 {
  padding-bottom: 5px !important;
}

.govuk-\!-static-padding-left-1 {
  padding-left: 5px !important;
}

.govuk-\!-static-padding-2 {
  padding: 10px !important;
}

.govuk-\!-static-padding-top-2 {
  padding-top: 10px !important;
}

.govuk-\!-static-padding-right-2 {
  padding-right: 10px !important;
}

.govuk-\!-static-padding-bottom-2 {
  padding-bottom: 10px !important;
}

.govuk-\!-static-padding-left-2 {
  padding-left: 10px !important;
}

.govuk-\!-static-padding-3 {
  padding: 15px !important;
}

.govuk-\!-static-padding-top-3 {
  padding-top: 15px !important;
}

.govuk-\!-static-padding-right-3 {
  padding-right: 15px !important;
}

.govuk-\!-static-padding-bottom-3 {
  padding-bottom: 15px !important;
}

.govuk-\!-static-padding-left-3 {
  padding-left: 15px !important;
}

.govuk-\!-static-padding-4 {
  padding: 20px !important;
}

.govuk-\!-static-padding-top-4 {
  padding-top: 20px !important;
}

.govuk-\!-static-padding-right-4 {
  padding-right: 20px !important;
}

.govuk-\!-static-padding-bottom-4 {
  padding-bottom: 20px !important;
}

.govuk-\!-static-padding-left-4 {
  padding-left: 20px !important;
}

.govuk-\!-static-padding-5 {
  padding: 25px !important;
}

.govuk-\!-static-padding-top-5 {
  padding-top: 25px !important;
}

.govuk-\!-static-padding-right-5 {
  padding-right: 25px !important;
}

.govuk-\!-static-padding-bottom-5 {
  padding-bottom: 25px !important;
}

.govuk-\!-static-padding-left-5 {
  padding-left: 25px !important;
}

.govuk-\!-static-padding-6 {
  padding: 30px !important;
}

.govuk-\!-static-padding-top-6 {
  padding-top: 30px !important;
}

.govuk-\!-static-padding-right-6 {
  padding-right: 30px !important;
}

.govuk-\!-static-padding-bottom-6 {
  padding-bottom: 30px !important;
}

.govuk-\!-static-padding-left-6 {
  padding-left: 30px !important;
}

.govuk-\!-static-padding-7 {
  padding: 40px !important;
}

.govuk-\!-static-padding-top-7 {
  padding-top: 40px !important;
}

.govuk-\!-static-padding-right-7 {
  padding-right: 40px !important;
}

.govuk-\!-static-padding-bottom-7 {
  padding-bottom: 40px !important;
}

.govuk-\!-static-padding-left-7 {
  padding-left: 40px !important;
}

.govuk-\!-static-padding-8 {
  padding: 50px !important;
}

.govuk-\!-static-padding-top-8 {
  padding-top: 50px !important;
}

.govuk-\!-static-padding-right-8 {
  padding-right: 50px !important;
}

.govuk-\!-static-padding-bottom-8 {
  padding-bottom: 50px !important;
}

.govuk-\!-static-padding-left-8 {
  padding-left: 50px !important;
}

.govuk-\!-static-padding-9 {
  padding: 60px !important;
}

.govuk-\!-static-padding-top-9 {
  padding-top: 60px !important;
}

.govuk-\!-static-padding-right-9 {
  padding-right: 60px !important;
}

.govuk-\!-static-padding-bottom-9 {
  padding-bottom: 60px !important;
}

.govuk-\!-static-padding-left-9 {
  padding-left: 60px !important;
}

.govuk-\!-text-align-left {
  text-align: left !important;
}

.govuk-\!-text-align-centre {
  text-align: center !important;
}

.govuk-\!-text-align-right {
  text-align: right !important;
}

.govuk-\!-font-size-80 {
  font-size: 3.3125rem !important;
  line-height: 1.03774 !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-font-size-80 {
    font-size: 5rem !important;
    line-height: 1 !important;
  }
}

@media print {
  .govuk-\!-font-size-80 {
    font-size: 53pt !important;
    line-height: 1.1 !important;
  }
}

.govuk-\!-font-size-48 {
  font-size: 2rem !important;
  line-height: 1.09375 !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-font-size-48 {
    font-size: 3rem !important;
    line-height: 1.04167 !important;
  }
}

@media print {
  .govuk-\!-font-size-48 {
    font-size: 32pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-36 {
  font-size: 1.5rem !important;
  line-height: 1.04167 !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-font-size-36 {
    font-size: 2.25rem !important;
    line-height: 1.11111 !important;
  }
}

@media print {
  .govuk-\!-font-size-36 {
    font-size: 24pt !important;
    line-height: 1.05 !important;
  }
}

.govuk-\!-font-size-27 {
  font-size: 1.125rem !important;
  line-height: 1.11111 !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-font-size-27 {
    font-size: 1.6875rem !important;
    line-height: 1.11111 !important;
  }
}

@media print {
  .govuk-\!-font-size-27 {
    font-size: 18pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-24 {
  font-size: 1.125rem !important;
  line-height: 1.11111 !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-font-size-24 {
    font-size: 1.5rem !important;
    line-height: 1.25 !important;
  }
}

@media print {
  .govuk-\!-font-size-24 {
    font-size: 18pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-19 {
  font-size: 1rem !important;
  line-height: 1.25 !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-font-size-19 {
    font-size: 1.1875rem !important;
    line-height: 1.31579 !important;
  }
}

@media print {
  .govuk-\!-font-size-19 {
    font-size: 14pt !important;
    line-height: 1.15 !important;
  }
}

.govuk-\!-font-size-16 {
  font-size: .875rem !important;
  line-height: 1.14286 !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-font-size-16 {
    font-size: 1rem !important;
    line-height: 1.25 !important;
  }
}

@media print {
  .govuk-\!-font-size-16 {
    font-size: 14pt !important;
    line-height: 1.2 !important;
  }
}

.govuk-\!-font-size-14 {
  font-size: .75rem !important;
  line-height: 1.25 !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-font-size-14 {
    font-size: .875rem !important;
    line-height: 1.42857 !important;
  }
}

@media print {
  .govuk-\!-font-size-14 {
    font-size: 12pt !important;
    line-height: 1.2 !important;
  }
}

.govuk-\!-font-weight-regular {
  font-weight: 400 !important;
}

.govuk-\!-font-weight-bold {
  font-weight: 600 !important;
}

.govuk-\!-width-full, .govuk-\!-width-three-quarters {
  width: 100% !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-width-three-quarters {
    width: 75% !important;
  }
}

.govuk-\!-width-two-thirds {
  width: 100% !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-width-two-thirds {
    width: 66.66% !important;
  }
}

.govuk-\!-width-one-half {
  width: 100% !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-width-one-half {
    width: 50% !important;
  }
}

.govuk-\!-width-one-third {
  width: 100% !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-width-one-third {
    width: 33.33% !important;
  }
}

.govuk-\!-width-one-quarter {
  width: 100% !important;
}

@media (width >= 40.0625em) {
  .govuk-\!-width-one-quarter {
    width: 25% !important;
  }
}

.gchqnet-header__logo {
  vertical-align: middle;
  width: 36px;
}

.gchqnet-header__logotext {
  vertical-align: middle;
  font-weight: 600;
}

@media (width >= 40.0625em) {
  .gchqnet-homepage__intro-text {
    display: none;
  }
}

@media (width >= 20em) and (width <= 40.0525em) {
  .gchqnet-homepage-aside__container {
    display: none;
  }
}

.gchqnet-play__image_container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.gchqnet-play__image {
  width: 75%;
}

.gchqnet-play__image_caption {
  text-align: center;
  margin-top: 1em;
}

.gchqnet-leaderboard-search__container {
  margin-bottom: 1em;
}

@media (width >= 20em) and (width <= 40.0525em) {
  .gchqnet-leaderboard-search__search-button {
    margin-top: .5em;
  }
}

@media (width <= 40.0525em) {
  .gchqnet-leaderboard-table__desktop-col {
    display: none;
  }
}

.gchqnet-pagination--center {
  justify-content: center;
}

.gchqnet-page-header-actions {
  text-align: justify;
  min-height: 40px;
  margin-bottom: 40px;
  font-size: 0;
}

.gchqnet-page-header-actions:after {
  content: "";
  clear: both;
  content: "";
  width: 100%;
  display: inline-block;
}

.gchqnet-page-header-actions__title [class^="govuk-heading-"] {
  text-align: left;
  margin-bottom: 10px;
}

@media (width >= 40.0625em) {
  .gchqnet-page-header-actions__title [class^="govuk-heading-"] {
    margin-bottom: 0;
  }

  .gchqnet-page-header-actions__title, .gchqnet-page-header-actions__actions {
    vertical-align: middle;
    display: inline-block;
  }
}

.gchqnet-page-header-actions__action:last-child {
  margin-bottom: 0;
}

@media (width >= 40.0625em) {
  .gchqnet-page-header-actions__action {
    margin-bottom: 0;
  }
}

.gchqnet-phase-banner__text {
  width: 100%;
}

.gchqnet-phase-banner__login-status {
  float: right;
}

@media (width >= 20em) and (width <= 48.0525em) {
  .gchqnet-phase-banner__service-info {
    display: none;
  }
}

.gchqnet-thanks__image_container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.gchqnet-thanks__image {
  width: 100%;
}

.gchqnet-thanks__image_caption {
  text-align: center;
  margin-top: 1em;
}
/*# sourceMappingURL=main.css.map */
